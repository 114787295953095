// Header.tsx
import React, { useEffect, useState } from 'react';
import './Header.css';
import logo from '../../images/logo.png';
import { FaUser } from 'react-icons/fa'; // Import user icon
import { Dropdown } from 'react-bootstrap'; // Import dropdown from Bootstrap
// import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
// import "react-bootstrap-submenu/dist/index.css";
import { getAuth, signOut } from 'firebase/auth';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { useIntl, FormattedMessage } from 'react-intl';
import firebaseApp from '../../firebase/firebaseApp';
import { svcLoadListMyIndexes, updateSelectedDocsIndex_auth } from '../../Services/servicesWrapper';
import { persistInProject } from '../../firebase/persistState';



interface LegalHeaderProps {
	title: string;
	logo: string;
}

const LegalHeader: React.FC<LegalHeaderProps> = () => {
	const intl = useIntl();
	const { authState, createNewProject, selectProject, refreshProjectsList, switchLanguage, allowFeature } = useSafeAuth();
	const [myDocsIndexes, setMyDocsIndexes] = useState<[] | null>(null);
	const [selectedDocsIndex_auth, setSelectedDocsIndex_auth] = persistInProject<string>('NONE', authState?.uid || '', authState?.project || '', "DocumentSearch.selectedIndex");
	//const [forceUpdate, setForceUpdate] = useState(0);

	function allowFeatures(listIndexes: any) {
		for (let index of listIndexes) {
			if (index.extra_features) {
				let allowed_features = index.extra_features;
				let features = allowed_features.split('|');
				for (let feature of features) {
					if (!!feature) {
						allowFeature(feature.trim().toLowerCase());
					}
				}
			}
		}
	}

	useEffect(() => {
		//if (authState.uid && authState.email && firebaseApp.appName == 'app' && !myDocsIndexes?.length) {
		if (authState.uid && authState.email && !myDocsIndexes?.length) {
			//console.log("1: " + authState.uid + " | " + authState.email);
			svcLoadListMyIndexes(authState).then((listIndexes) => {
				//console.log("2: ", listIndexes);
				if (listIndexes?.length) {
					//console.log("3: ", listIndexes);
					allowFeatures(listIndexes);
					if (firebaseApp.appName == 'app') {
						let indexes = listIndexes.filter((index: any) => index.is_index);
						setMyDocsIndexes(indexes);
					}
				}
			});
		}
	}, [authState.uid, authState.email]);
	//console.log("4: Rendering with", myDocsIndexes);


	useEffect(() => {
		//console.log('1: myDocsIndexes.length=' + (myDocsIndexes?.length || 0) + '; selectedDocsIndex_auth=' + (selectedDocsIndex_auth || ''));
		if (myDocsIndexes && myDocsIndexes.length > 0 && (!selectedDocsIndex_auth || selectedDocsIndex_auth == 'NONE')) {
			//console.log("2: (myDocsIndexes as any)[0]?.commands?.[0] || 'NONE' = " + (myDocsIndexes as any)[0]?.commands?.[0] || 'NONE');
			setSelectedDocsIndex_auth((myDocsIndexes as any)[0]?.commands?.[0] || 'NONE');
		}
	}, [myDocsIndexes]);
	useEffect(() => {
		//console.log("3: selectedDocsIndex_auth=" + selectedDocsIndex_auth || '');
		if (selectedDocsIndex_auth && selectedDocsIndex_auth != 'NONE') {
			updateSelectedDocsIndex_auth(selectedDocsIndex_auth);
		}
	}, [selectedDocsIndex_auth]);

	const handleRefreshProjectsList = async () => {
		await refreshProjectsList();
	}

	// Function to handle the logout
	const handleLogout = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				window.location.reload();
			})
			.catch((error) => {
				console.error('Error logging out:', error);
			});
	};
	const UNIQUE = () => {
		const datetime = new Date().toISOString(); // e.g., "2023-09-14T08:21:00.000Z"
		return datetime.replace('T', ' ').replace('Z', '');
	};

	function handleLanguageChange(languageCode: string) {
		switchLanguage(languageCode);
	}

	function handleDocumentIndexSelection(selectedIndex: string) {
		updateSelectedDocsIndex_auth(selectedIndex);
		setSelectedDocsIndex_auth(selectedIndex);
	}



	return (
		<header className="legal-header">
			<div className="project-menu-container">
				<Dropdown className="project-menu" onToggle={handleRefreshProjectsList}>
					<Dropdown.Toggle variant="light">
						<span className='menu-project-header'><FormattedMessage id="header.project" /></span>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item className="project-dropdown-item" id="menuNewProject" onClick={() => {
							const newProjectId = "Project " + UNIQUE();
							if (newProjectId) {
								createNewProject(newProjectId);
								//refreshProjectsList();
							}
						}}><FormattedMessage id="header.new_project" /></Dropdown.Item>
						<Dropdown.Divider />
						{authState.projects.map((project) => (
							<Dropdown.Item
								className={`project-dropdown-item ${project.id === authState.project ? 'active' : ''}`}
								key={project.id}
								onClick={() => selectProject(project.id)}
							>
								{project.name}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>


			</div>
			<span className='logo-container'>
				<img src={logo} alt="Company Logo" className="legal-logo" />
				<h1 className="legal-title"><FormattedMessage id={firebaseApp.appName == 'legal' ? "header.legal.title" : "header.app.title"} /></h1>
			</span>
			<div className="account-menu-container">
				<Dropdown className="account-menu">
					<Dropdown.Toggle variant="light">
						<FaUser className='menu-account-header' />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Header>{authState.user ? authState.user.displayName : ''}</Dropdown.Header>
						<Dropdown.ItemText>{authState.user ? authState.user.email : ''}</Dropdown.ItemText>
						<Dropdown.Divider />

						<Dropdown.Header><FormattedMessage id="header.language" /></Dropdown.Header>
						<Dropdown.Item onClick={() => handleLanguageChange('en')}
							className={intl.formatMessage({ id: 'global.language' }) == 'en' ? 'active' : ''}
						><FormattedMessage id="header.english" /></Dropdown.Item>
						<Dropdown.Item onClick={() => handleLanguageChange('he')}
							className={intl.formatMessage({ id: 'global.language' }) == 'he' ? 'active' : ''}
						><FormattedMessage id="header.hebrew" /></Dropdown.Item>
						<Dropdown.Item onClick={() => handleLanguageChange('ro')}
							className={intl.formatMessage({ id: 'global.language' }) == 'ro' ? 'active' : ''}
						><FormattedMessage id="header.romanian" /></Dropdown.Item>

						<Dropdown.Divider />
						{firebaseApp.appName == 'app' ?
							<>
								{/* Section for Document Indexes */}
								<Dropdown.Header><FormattedMessage id="header.documentIndexes" /></Dropdown.Header>
								{myDocsIndexes && myDocsIndexes.map((index: any, idx) => (
									<Dropdown.Item
										key={idx}
										onClick={() => handleDocumentIndexSelection(index.commands[0])}
										className={index.commands.includes(selectedDocsIndex_auth) ? 'active' : ''}
									>
										{index.organization}
									</Dropdown.Item>
								))}
								<Dropdown.Divider />
							</>
							: <></>
						}

						<Dropdown.Item onClick={handleLogout}><FormattedMessage id="header.logout" /></Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>


			</div>
		</header>
	);
};

export default LegalHeader;

// LegalModuleJudgementsMarked.tsx
import { useState, useContext } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import styles from '../UIGeneral/styles.module.css';
import '../LegalModuleJudgements/LegalModuleJudgements.css';
import './LegalModuleJudgementsMarked.css';
import { IDoc } from '../DocumentSearch/DocumentTypes'
import { FormattedMessage, useIntl } from 'react-intl';
import JudgementsContext from '../LegalModuleJudgements/JudgementsContext';
import { firestoreDeleteMarkedJudgement, firestoreLoadMarkedJudgements, firestoreSaveMarkedJudgement } from '../../firebase/firebaseCaching';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { svcLoadGeometriesWithRelevance } from '../../Services/servicesWrapper';



const LegalModuleJudgementsMarked = () => {
	//function LegalModuleJudgementsMarked() {
	const intl = useIntl();
	const { authState } = useSafeAuth();
	const [selectedDoc, setSelectedDoc] = useState<IDoc | null>(null);
	//const [userQuery, setUserQuery] = useState<string>('');
	//const [searchType, setSearchType] = useState<string>('');
	//const [results, setResults] = useState<any[]>([]);
	const { selectedJudgements, setSelectedJudgements } = useContext(JudgementsContext);

	// Handle document selection
	const handleDocumentSelect = async (doc: IDoc) => {
		if (!doc.piecesWithGeometry) {
			svcLoadGeometriesWithRelevance(authState, doc.adoptedQuery || '', doc.url, "Judgements").then((value) => {
				const updatedDoc = { ...doc, piecesWithGeometry: value };
				updatedDoc.piecesWithGeometry = value;
				//doc.piecesWithGeometry = value;
				setSelectedDoc(updatedDoc);
				//onDocumentSelect(updatedDoc);
			});
		}
		setSelectedDoc(doc); // Update the state variable with the selected document
		//onDocumentSelect(doc);
	};

	// const onUserQueryChange = (adoptedQuery: string, searchType: string) => {
	// 	setUserQuery(adoptedQuery);
	// 	setSearchType(searchType);
	// }


	const columns = [
		{ id: 'is_selected', title: " ", render: (doc: any) => doc?.is_selected },
		{ id: 'index', title: "#", render: (index: any) => index },
		{ id: 'relevance', title: "@", render: (doc: any) => doc?.score },
		{ id: 'caseNumber', title: intl.formatMessage({ id: "legalModuleJudgements.caseNumber" }), render: (doc: any) => doc?.metaData?.case_number },
		{ id: 'decisionDate', title: intl.formatMessage({ id: "legalModuleJudgements.decisionDate" }), render: (doc: any) => doc?.metaData?.decision_date },
		{
			id: 'court', title: intl.formatMessage({ id: "legalModuleJudgements.court" }), render: (doc: any) => `${doc?.metaData?.court || ''}` +
				((doc?.metaData?.court || '').indexOf(doc?.metaData?.area || '') >= 0 ||
					(doc?.metaData?.area || '').indexOf('undefined') >= 0 || (doc?.metaData?.court || '').indexOf('בית המשפט העליון') >= 0 ?
					'' : ` - ${doc?.metaData?.area || ''}`)
		},
		{ id: 'description', title: intl.formatMessage({ id: "legalModuleJudgements.description" }), render: (doc: any) => doc?.metaData?.title || doc.overview }
	];


	// const checkIsSelected = (doc: IDoc) => {
	// 	// This needs to return true when a matching judgement is found
	// 	return !!selectedJudgements.find((judgement) => judgement.url === doc.url);
	// };

	const checkIsMarked = (doc: IDoc) => {
		// Attempt to find the document by URL
		const found = selectedJudgements.find((judgement) => judgement.url === doc.url);

		// Check both if the document is found and if its isSelected property is true
		return !!found && found.isMarked;
	};



	const markJudjement = (doc: IDoc, isMarked: boolean) => {
		const index = selectedJudgements.findIndex((judgement) => judgement.url === doc.url);

		if (index !== -1) {
			// Judgement found, update its isSelected status
			const updatedJudgements = [...selectedJudgements];
			updatedJudgements[index] = { ...updatedJudgements[index], isMarked };
			setSelectedJudgements(updatedJudgements);
			// Firestore update logic here for the individual judgement
			firestoreSaveMarkedJudgement(authState?.uid || '', authState?.project || '', updatedJudgements[index]);
		} else {
			// New judgement, add to the array and mark as selected or unselected
			const newJudgement = { ...doc, isMarked };
			setSelectedJudgements([...selectedJudgements, newJudgement]);
			// Firestore update logic here for the new judgement
			firestoreSaveMarkedJudgement(authState?.uid || '', authState?.project || '', newJudgement);
		}
	};

	const removeUnmarkedClick = async () => {
		// Loop through the selectedJudgements array
		for (const judgement of selectedJudgements) {
			// Check if isSelected is false
			if (!judgement.isMarked) {
				// Call firestoreDeleteSelectedJudgement function to delete
				await firestoreDeleteMarkedJudgement(authState?.uid || '', authState?.project || '', judgement);
			}
		}
		const reloadedJudgements = await firestoreLoadMarkedJudgements(authState?.uid || '', authState?.project || '');
		// Assuming setSelectedJudgements is a state setter from useState in your context or component
		setSelectedJudgements(reloadedJudgements);
	};


	// const removeUnselectedClick = () => {
	// 	setSelectedJudgements(selectedJudgements.filter((judgement) => judgement.isSelected));
	// }


	return (
		<div className={[styles.Container, "legal-module-search"].join(" ")}>
			<PanelGroup className="legal-module-panes" autoSaveId="moduleJudgementsPanesResizer" direction="horizontal">
				<Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 2 : 1}>

					<div className="results-container selected-judgements-panel">
						<div className="search-results-header">
							<label className="form-label">        <FormattedMessage id="LegalModuleJudgementsMarked.judgementsList" /></label>
							<button id="removeUnselectedBtn" className="btn btn-primary" onClick={removeUnmarkedClick}					>
								<FormattedMessage id="LegalModuleJudgementsMarked.buttonRemoveUnselected" />
							</button>
						</div>

						<table className="table table-hover table-striped1" id="tableSearchResults">
							<thead className="thead-secondary">
								<tr>
									{columns.map((col, idx) => (
										<th key={idx}
											className={
												col.id === 'caseNumber' ? 'column-case-number' : (
													col.id === 'relevance' ? 'column-relevance' : (
														col.id === 'index' ? 'column-index' : (
															col.id === 'is_selected' ? 'column-is-selected' : '')))}
										>{col.title}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{selectedJudgements.map((doc, index) => (
									<tr key={index} onClick={() => handleDocumentSelect(doc)} className={selectedDoc === doc ? 'table-primary' : ''}>
										{columns.map((col, colIdx) => {
											if (col.id === 'is_selected') {
												// Render a checkbox in the is_selected column
												return (
													<td key={colIdx}>
														<input
															type="checkbox"
															checked={checkIsMarked(doc)}
															onChange={(e) => markJudjement(doc, e.target.checked)}
														/>
													</td>
												);
											} else {
												// Render other columns as before
												return (
													<td key={colIdx} className={
														col.id === 'caseNumber' ? 'column-case-number' : (
															col.id === 'relevance' ? 'column-relevance' : (
																col.id === 'index' ? 'column-index' : ''))
													}>{col.id === 'index' ? index + 1 : col.render(doc)}</td>
												);
											}
										}
										)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Panel>
				<ResizeHandle className='splitter' />
				<Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 1 : 2}>
					<div className={[styles.PanelContent, "legal-preview"].join(" ")}>
						<DocumentPreview
							iframeUrl={selectedDoc?.url ?? ''}
							userQuery={selectedDoc?.adoptedQuery ?? ''}
							searchType={''}
							piecesWithGeometry={selectedDoc?.piecesWithGeometry}
							ParentID='MarkedJudgements'
							ContentType='Judgements'
							metaData={{
								JName: selectedDoc?.overview ?? '', JNumber: selectedDoc?.metaData?.case_number ?? '',
								JJudge: selectedDoc?.metaData?.judge ?? '', JCourt: selectedDoc?.metaData?.court ?? '',
								JDistrict: selectedDoc?.metaData?.area ?? '', JDecisionDate: selectedDoc?.metaData?.decision_date ?? ''
							}}
						/>
					</div>
				</Panel>
			</PanelGroup>
		</div>
	);
}

export default LegalModuleJudgementsMarked;

// ModuleChat.tsx

import styles from '../UIGeneral/styles.module.css';
import './ModuleChat.css';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import { FormattedMessage, useIntl } from 'react-intl';
import deleteIcon from '../../images/icon-delete.png';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import noteIcon from '../../images/icon-note.png';
import wordIcon from '../../images/word_icon.png';
import waitIcon from '../../images/loading_spinner_transparent.gif';
import { useEffect, useRef, useState } from 'react';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import { firestoreDeleteChat, firestoreLoadChatMessages, firestoreLoadChats, firestoreSaveChat } from '../../firebase/firebaseCaching';
import { Chat, ChatMessage, textToHTML } from '../BaseChatElements/chatUtils';
import BaseChatComponent from '../BaseChatElements/BaseChatComponent';
import { downloadAsWord, svcChat } from '../../Services/servicesWrapper';

function ModuleChat() {
    const intl = useIntl();
    const { authState } = useSafeAuth();
    const [currentChat, setCurrentChat] = useState<Chat>({ id: uuidv4(), title: intl.formatMessage({ id: 'chat.newChatTitle' }), last_updated: Date.now() });
    const [chats, setChats] = useState<Chat[]>([]);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [chatToDeleteId, setChatToDeleteId] = useState<string>("");
    const [isExportDownloading, setIsExportDownloading] = useState<{ [key: string]: boolean }>({});

    const fetchChats = async () => {
        const loadedChats = await firestoreLoadChats(authState.uid || '', authState.project || '');
        const sortedChats = loadedChats.sort((a, b) => b.last_updated - a.last_updated);
        setChats(sortedChats);

        // If no chats initially, create a new one 
        if (chats.length === 0) {
            const newChat: Chat = {
                id: uuidv4(),
                title: intl.formatMessage({ id: 'chat.newChatTitle' }),
                last_updated: Date.now()
            };
            setCurrentChat(newChat);
        }
    };

    useEffect(() => {
        fetchChats();
        //handleSetTitleForCurrentChat(); // Add this line
    }, []);

    useEffect(() => {
        if (chatMessages.length > 0 && currentChat.title != intl.formatMessage({ id: 'chat.newChatTitle' })) {
            // Check if the currentChat is already in the chats list
            const existingChatIndex = chats.findIndex(chat => chat.id === currentChat.id);
            let updatedChats: Chat[] = [];
            if (existingChatIndex === -1) { // Chat is not in the list
                updatedChats = [currentChat, ...chats];
            } else { // Chat is already in the list
                updatedChats = [...chats.slice(0, existingChatIndex), currentChat, ...chats.slice(existingChatIndex + 1)];
            }
            setChats(updatedChats);
        }
    }, [currentChat.title]);

    const handleSetTitleForCurrentChat = async () => {
        const messages = await firestoreLoadChatMessages(authState.uid || '', authState.project || '', currentChat.id);
        const lastMessage = messages[messages.length - 1];
        if (lastMessage) {
            //considerAddChatTitle(lastMessage.text, '');
        }
    }

    const considerAddChatTitle = (chatMessages: ChatMessage[], uPrompt: string, aResponse: string) => {
        if (chatMessages.length <= 1) {
            setCurrentChat(prev => { return { ...prev, title: '' }; });
            let lang = intl.formatMessage({ id: 'global.language' });

            let fixedResponse = textToHTML(aResponse, lang, null);

            let prompt = `raw|small:I have a chat between a user and AI assistant:\n===\nUser Prompt:\n\n${uPrompt}\n===\nAssistant response:\n\n${fixedResponse}\n===\n`;
            prompt += `Please provide a consise title (just a couple of words - a single line phrase) for this chat. Do NOT include any explanations or questions or pre-words (like 'title:'), only provide the title itself directly!\nThe title should be in ${lang} language.\n===\nTitle:`;
            //svcChat(authState.uid || '', authState.email || '', 'system_title', prompt, handleSetTitleWordResponse, handleSetTitleCompleted);
            svcChat(authState, currentChat.id, 'system_title', prompt, handleSetTitleWordResponse, handleSetTitleCompleted);
        }
    }

    function removeQuotes(inputString: string): string {
        return inputString.replace(/^"|"$/g, '');
    }

    const handleSetTitleCompleted = async (request: string, title: string) => {
        title = removeQuotes(title);
        let updatedChat = { ...currentChat, title: title };
        setCurrentChat(updatedChat);

        await firestoreSaveChat(authState.uid || '', authState.project || '', updatedChat);

        handleSetTitleForCurrentChat(); // Trigger title update for newly saved chat   
    }

    const handleSetTitleWordResponse = (word: string) => {
        setCurrentChat(prev => { return { ...prev, title: (prev.title == 'New Chat') ? `${word}` : `${prev.title}${word}` }; });
    }

    const saveChatMessage_UpdateChats = (msg: ChatMessage) => {
        firestoreSaveChat(authState.uid || '', authState.project || '', currentChat);

        // Check if the currentChat is already in the chats list
        const existingChatIndex = chats.findIndex(chat => chat.id === currentChat.id);
        let updatedChats: Chat[] = [];
        if (existingChatIndex === -1) { // Chat is not in the list
            updatedChats = [currentChat, ...chats];
        } else { // Chat is already in the list
            updatedChats = [currentChat, ...chats.slice(0, existingChatIndex), ...chats.slice(existingChatIndex + 1)];
        }
        setChats(updatedChats);
    }

    const handleChatSelect = async (chat: Chat) => {
        setCurrentChat(chat);
    };

    const handleNewChat = () => {
        const newChat: Chat = {
            id: uuidv4(),
            title: intl.formatMessage({ id: 'chat.newChatTitle' }),
            last_updated: Date.now(),
        };
        setCurrentChat(newChat);
    };

    const handleDeleteClick = (e: any, chatId: string) => {
        e.stopPropagation();
        setChatToDeleteId(chatId);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = (chatId: string) => {
        firestoreDeleteChat(authState.uid || '', authState.project || '', chatId);
        setShowDeleteModal(false);
        setChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
        handleNewChat();
    };

    const handleChatExportClick = async (e: any, chatId: string) => {
        //e.stopPropagation();
        setIsExportDownloading(prev => { return { ...prev, [chatId]: true } });
        try {
            const lang = intl.formatMessage({ id: 'global.language' });
            //const lastUserMessage = findLastUserMessageBefore(message);
            let arrMessages = chatMessages.map((message) => (message.type == 'u' ? 'U:' : 'A:') + textToHTML(message.text, lang, null));
            const downloadUrl = await downloadAsWord(authState, chatId, '', arrMessages, lang);
            if (downloadUrl) {
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `response_${chatId}.docx`;
                a.click();
            }
        } catch (error) {
            console.error('Download failed:', error);
        } finally {
            setIsExportDownloading(prevState => ({ ...prevState, [chatId]: false }));
        }
    };

    return (
        <div className={[styles.Container, "module-chat"].join(" ")}>
            <PanelGroup className="legal-module-panes" autoSaveId="moduleChatPanesResizer" direction="horizontal">
                <Panel className={styles.Panel} collapsible={false} defaultSize={10} minSize={10} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 2 : 1}>
                    <div className='panelDiv panel-chat'>
                        <Button variant="primary" className="mb-3 w-100" onClick={handleNewChat}>
                            <FormattedMessage id="chat.buttonNewChat" />
                        </Button>
                        <div className="mb-2"><FormattedMessage id="chat.previousChats" /></div>
                        <ListGroup variant="flush">
                            {chats.map(chat => (
                                <ListGroup.Item
                                    key={chat.id}
                                    action
                                    onClick={() => handleChatSelect(chat)}
                                    className={`d-flex align-items-center chat-title-container ${chat.id === currentChat.id ? 'selected-chat' : ''}`}
                                >
                                    <div className="chat-icon-container">
                                        <img src={noteIcon} alt="Chat icon" width={24} />
                                    </div>
                                    <div className="chat-title">{chat.title || chat.id}</div>
                                    {/* <div className="delete-chat-icon" onClick={(e) => handleDeleteClick(e, chat.id)}>
                                        <img src={deleteIcon} alt="Delete icon" width={10} />
                                    </div> */}
                                    <div className="chat-actions">
                                        <div className="export-chat-icon" >
                                            <div className='icon-wrapper-chat'>
                                                {
                                                    chat.id !== currentChat.id ? <></> :
                                                        <img alt="Export to Word"
                                                            onClick={(e) => handleChatExportClick(e, chat.id)}
                                                            src={isExportDownloading[chat.id] ? waitIcon : wordIcon}
                                                            className={isExportDownloading[chat.id] ? 'img-export-chat-wait-icon' : 'img-export-chat-word-icon'}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <div className="delete-chat-icon" onClick={(e) => handleDeleteClick(e, chat.id)}>
                                            <img src={deleteIcon} alt="Delete icon" width={10} />
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </Panel>

                <ResizeHandle className='splitter' />
                <Panel className={styles.Panel} collapsible={false} defaultSize={90} minSize={30} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 1 : 2}>
                    {/* Render the BaseChatComponent here */}
                    <BaseChatComponent currentChat={currentChat}
                        setCurrentChat={setCurrentChat}
                        considerAddChatTitle={considerAddChatTitle}
                        saveChatMessage_UpdateChats={saveChatMessage_UpdateChats}
                        chatMessages={chatMessages} setChatMessages={setChatMessages}
                        instanceType='ModuleChat'
                    />
                </Panel>
            </PanelGroup>


            {/* Delete Chat Modal */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete Chat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this chat?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => handleConfirmDelete(chatToDeleteId)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default ModuleChat;

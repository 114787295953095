//index.tsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import Footer from './components/Footer/Footer';
//import AppRoutes from './Routes'; // Adjust path if necessary
import { AuthProvider } from './firebase/AuthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import { clearLocalCache } from './firebase/firebaseCaching';
//import { clearFirestoreCache } from './firebase/firestoreDb';



const container = document.getElementById('root');

clearLocalCache();
//clearFirestoreCache();

if (container !== null) {
	const root = createRoot(container);
	root.render(

		<React.StrictMode>
			<div className="app-container">
				<div className="main-content">
					<AuthProvider children={undefined} />
				</div>
				<Footer />
			</div>
		</React.StrictMode>

		// <React.StrictMode>

		// 	<AuthProvider children={undefined} >
		// 		{/* <AppRoutes /> */}
		// 	</AuthProvider>

		// </React.StrictMode>
	);
}

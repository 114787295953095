// XpedITeAIAssistant.tsx

import styles from "./XpedITeAIAssistant.module.css";
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { firestoreLoadChat, firestoreSaveChat, firestoreLoadChatMessages } from '../../firebase/firebaseCaching';
import { Chat, ChatMessage } from '../BaseChatElements/chatUtils';
import BaseChatComponent from '../BaseChatElements/BaseChatComponent';
import busIcon from '../../images/wisanna_icon.png';
import { set } from "lodash";

function XpedITeAIAssistant() {
    const intl = useIntl();
    const { authState } = useSafeAuth();
    const [chat, setChat] = useState<Chat | null>(null);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [lastLatestTime, setLastLatestTime] = useState(0);



    const webChatId = authState.uid ? `xpedite_${authState.uid}` : 'default_xpedite_chat'; // Unique chat ID per user
    let chatMessagesLoadCalled = false;

    useEffect(() => {

        const loadChatAndMessages = async () => {
            setIsLoaded(true);

            chatMessagesLoadCalled = true;

            let loadedChat = await firestoreLoadChat(authState.uid || '', authState.project || '', webChatId);

            // Create a new chat if it doesn't exist
            if (!loadedChat) {
                loadedChat = {
                    id: webChatId,
                    title: intl.formatMessage({ id: 'chat.webChatTitle' }), // Or any default title
                    last_updated: Date.now(),
                };
            }

            // Load messages
            const messages = await firestoreLoadChatMessages(authState.uid || '', authState.project || '', loadedChat.id);
            messages.sort((a, b) => a.date - b.date); // Sort by date

            setChat(loadedChat);
            setChatMessages(messages);

        };

        if (!isLoaded && !chatMessagesLoadCalled && authState.uid && authState.project) { loadChatAndMessages(); }

    }, [isLoaded]);

    // Update the chat state whenever messages change 
    useEffect(() => {
        if (chat && chatMessages.length > 0 && chatMessages[chatMessages.length - 1].date > lastLatestTime) {
            let latestMsg = chatMessages[chatMessages.length - 1];
            setLastLatestTime(latestMsg.date);
            const updatedChat = { ...chat, last_updated: Date.now() };
            setChat(updatedChat);
            firestoreSaveChat(authState.uid || '', authState.project || '', updatedChat);
        }
    }, [chatMessages]);

    if (chat) {
        //debugger;
    }


    // <div className={styles.header}>
    //             <div className={styles.headerIcon}>
    //                 <img src={busIcon} alt="Bus Icon" />
    //             </div>
    //             <div className={styles.headerTitle}>XpedITe Analysis AI</div> {/* Centered title */}
    //         </div>

    // Since there's only one chat, pass it directly to BaseChatComponent
    return (
        <div className={styles.webChatContainer}>
            <div className={styles.chatArea}>
                {chat && (
                    <BaseChatComponent
                        currentChat={chat}
                        setCurrentChat={setChat}
                        chatMessages={chatMessages}
                        setChatMessages={setChatMessages}
                        considerAddChatTitle={() => { }} // Simplified, as title functionality might not be needed
                        instanceType="XpedITeAIAssistant"
                    />
                )}
            </div>
        </div>
    );
}

export default XpedITeAIAssistant;
import { persistInProject } from "../../firebase/persistState";
import { useSafeAuth } from '../../firebase/AuthProvider';
import './ModuleGeneral.css';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import styles from '../UIGeneral/styles.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import firebaseApp from "../../firebase/firebaseApp";
import ModuleGeneralContext, { ModuleGeneralState } from './ModuleGeneralContext';


const ModuleGeneral = () => {
  const intl = useIntl();
  const { authState, refreshProjectsList } = useSafeAuth();
  let isLegal = firebaseApp.appName == 'legal';

  // Setting up persistInProject for each field
  //const [projectName, setProjectName] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.projectName');
  const [projectName, setProjectName] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.projectName');
  const [plaintiff, setPlaintiff] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.plaintiff');
  const [defendant, setDefendant] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.defendant');
  const [description, setDescription] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.description');
  const [court, setCourt] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.court');
  const [caseNumbers, setCaseNumbers] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.caseNumbers');
  const [additionalInfo, setAdditionalInfo] = persistInProject<string>('', authState?.uid || '', authState?.project || '', 'General.additionalInfo');

  // Generalized handler to update state
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (e.target.id) {
      case 'projectName':
        setProjectName(e.target.value);
        refreshProjectsList();
        break;
      case 'plaintiff':
        setPlaintiff(e.target.value);
        break;
      case 'defendant':
        setDefendant(e.target.value);
        break;
      case 'description':
        setDescription(e.target.value);
        break;
      case 'court':
        setCourt(e.target.value);
        break;
      case 'caseNumbers':
        setCaseNumbers(e.target.value);
        break;
      case 'additionalInfo':
        setAdditionalInfo(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="legal-module-general">
      <PanelGroup className="legal-module-panes" autoSaveId="moduleGeneralPanesResizer" direction="horizontal">
        {/* Left Pane */}
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 2 : 1}>
          <div className={[styles.PanelContent, "panel-general"].join(" ")}>
            <div className="details-container row">
              <label htmlFor="projectName" className="form-label"><FormattedMessage id="legalModuleGeneral.projectName" />              </label>
              <div className="input-group mb-3 search-container">
                <textarea
                  className="form-control custom-textarea"
                  id="projectName"
                  rows={2}
                  value={projectName || ''}
                  onChange={handleInputChange}
                />
              </div>
              {isLegal ?
                <>
                  <label htmlFor="plaintiff" className="form-label"><FormattedMessage id="legalModuleGeneral.plaintiff" /></label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="plaintiff"
                      value={plaintiff || ''}
                      onChange={handleInputChange}
                    />
                  </div>

                  <label htmlFor="defendant" className="form-label"><FormattedMessage id="legalModuleGeneral.defendant" /></label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="defendant"
                      value={defendant || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                </> : <></>}
              <label htmlFor="description" className="form-label"><FormattedMessage id="legalModuleGeneral.description" /></label>
              <div className="input-group mb-3 search-container">
                <textarea
                  className="form-control custom-textarea"
                  id="description"
                  rows={7}
                  value={description || ''}
                  onChange={handleInputChange}
                />
              </div>

            </div>
          </div>
        </Panel>

        <ResizeHandle className='splitter' />

        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 1 : 2}>
          <div className={[styles.PanelContent, "panel-general"].join(" ")}>
            <div className="details-container row">
              {isLegal ?
                <>
                  <label htmlFor="court" className="form-label"><FormattedMessage id="legalModuleGeneral.court" /></label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="court"
                      value={court || ''}
                      onChange={handleInputChange}
                    />
                  </div>

                  <label htmlFor="caseNumbers" className="form-label"><FormattedMessage id="legalModuleGeneral.caseNumbers" /></label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="caseNumbers"
                      value={caseNumbers || ''}
                      onChange={handleInputChange}
                    />
                  </div>
                </> : <></>}
              <label htmlFor="additionalInfo" className="form-label"><FormattedMessage id="legalModuleGeneral.additionalInfo" /></label>
              <div className="input-group mb-3">
                <textarea
                  className="form-control custom-textarea"
                  id="additionalInfo"
                  rows={11}
                  value={additionalInfo || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </Panel>

      </PanelGroup>
    </div>
  );
}

export default ModuleGeneral;

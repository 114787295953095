// ModuleDocumentGenerate.tsx
import React, { useEffect, useState } from 'react';
import './ModuleDocumentGenerate.css';
import DocGenAccordion from './DocGenAccordion';
import DocGenSelectDocumentType from './DocGenSelectDocumentType';
import DocGenShowGuidelines from './DocGenShowGuidelines';
import DocGenContentInput, { TextInput } from './DocGenContentInput';
import DocGenContentValidation, { ContentGapInfo } from './DocGenContentValidation';
import DocGenDocumentGeneration from './DocGenDocumentGeneration';
import { useIntl } from 'react-intl';
import { firestoreDocGenLoadInputTexts } from '../../firebase/firebaseCaching';
import { useSafeAuth } from '../../firebase/AuthProvider';
import guidelinesDocumentTypesData from './documentTypes.json';
import DocGenDocumentCheck, { DocumentGapInfo } from './DocGenDocumentCheck';
import { persistInProject } from '../../firebase/persistState';
import DocGenDocumentPartsCreation, { DocumentPartState } from './DocGenDocumentPartsCreation';


type GuidelineDetails = { [key: string]: string | string[] | undefined };

// Assuming guidelines are part of DocumentType
export type GuidelinesDocumentType = {
    id: string;
    name: { [locale: string]: string };
    guidelines: { [locale: string]: GuidelineDetails };
    documentParts: { [locale: string]: string[] };
};


const ModuleDocumentGenerate: React.FC = () => {
    const intl = useIntl();
    const { authState } = useSafeAuth();
    const [selectedDocType, setSelectedDocType] = useState<string>("");
    const [guidelines, setGuidelines] = useState<string>("");
    const [guidelinesEn, setGuidelinesEn] = useState<string>("");
    const [textInputs, setTextInputs] = useState<TextInput[]>([]);
    const [includeProjectSummary, setIncludeProjectSummary] = useState(true);
    const [includeMarkedJudgements, setIncludeMarkedJudgements] = useState(false);
    const [guidelinesDocumentTypes, setGuidelinesDocumentTypes] = useState<GuidelinesDocumentType[]>([]);
    const [contentGaps, setContentGaps] = persistInProject<ContentGapInfo[] | null>([], authState?.uid || '', authState?.project || '', "DocGen.contentGaps");
    const [documentPartsStates, setDocumentPartsStates] = persistInProject<{ [part: string]: DocumentPartState }>({}, authState?.uid || '', authState?.project || '', "DocGen.documentParts");
    const [documentUrl, setDocumentUrl] = persistInProject<string>("", authState?.uid || '', authState?.project || '', "DocGen.createdDocumentUrl");
    const [documentGaps, setDocumentGaps] = persistInProject<DocumentGapInfo[] | null>([], authState?.uid || '', authState?.project || '', "DocGen.documentGaps");
    const currentLocale = authState.currentLocale || 'en';


    useEffect(() => {
        // Here, we assume that the JSON data is correctly structured
        const loadedGuidelinesDocumentTypes: GuidelinesDocumentType[] = guidelinesDocumentTypesData.documentTypes;
        setGuidelinesDocumentTypes(loadedGuidelinesDocumentTypes);

        const loadInputTexts = async () => {
            const inputtexts = await firestoreDocGenLoadInputTexts(authState?.uid || '', authState?.project || '');
            if (inputtexts) {
                setTextInputs(inputtexts);
            }
        };
        loadInputTexts();
    }, []);


    useEffect(() => {
        const docType = guidelinesDocumentTypes.find(type => type.id === selectedDocType);
        if (docType && docType.guidelines) {
            const guidelinesText = extractGuidelines(docType, currentLocale);
            setGuidelines(guidelinesText);
            const guidelinesTextEn = extractGuidelines(docType, "en");
            setGuidelinesEn(guidelinesTextEn);
        } else {
            setGuidelines('');
        }
    }, [selectedDocType, authState.currentLocale, guidelinesDocumentTypes]);


    function extractGuidelines(docType: GuidelinesDocumentType, currentLocale: string) {
        const localeGuidelines = docType.guidelines[currentLocale] || docType.guidelines.en;
        const guidelinesText = Object.entries(localeGuidelines)
            .map(([key, value]) => `${key}:\n    ${Array.isArray(value) ? value.join('\n    ') : value}`)
            .join('\n\n');
        return guidelinesText;
    }

    const accordionItems = [
        {
            header: intl.formatMessage({ id: 'DocGenSelectDocumentType.header' }),
            content: <DocGenSelectDocumentType selectedDocType={selectedDocType} setSelectedDocType={setSelectedDocType} />
        },
        {
            header: intl.formatMessage({ id: 'DocGenShowGuidelines.header' }),
            content: <DocGenShowGuidelines selectedDocType={selectedDocType} setGuidelines={setGuidelines} guidelines={guidelines}
            />
        },
        {
            header: intl.formatMessage({ id: 'DocGenContentInput.header' }),
            content: <DocGenContentInput textInputs={textInputs} setTextInputs={setTextInputs} includeProjectSummary={includeProjectSummary}
                setIncludeProjectSummary={setIncludeProjectSummary} includeMarkedJudgements={includeMarkedJudgements}
                setIncludeMarkedJudgements={setIncludeMarkedJudgements}
            />
        },
        {
            header: intl.formatMessage({ id: 'DocGenContentValidation.header' }),
            content: <DocGenContentValidation includeProjectSummary={includeProjectSummary} includeMarkedJudgements={includeMarkedJudgements}
                textInputs={textInputs} guidelines={guidelines} contentGaps={contentGaps || []} setContentGaps={setContentGaps}
            />
        },
        {
            header: intl.formatMessage({ id: 'DocGenDocumentPartsCreation.header' }),
            content: <DocGenDocumentPartsCreation selectedDocType={selectedDocType} guidelinesDocumentTypes={guidelinesDocumentTypes}
                includeProjectSummary={includeProjectSummary} includeMarkedJudgements={includeMarkedJudgements} textInputs={textInputs}
                guidelinesEn={guidelinesEn} guidelines={guidelines}
                documentPartsStates={documentPartsStates} setDocumentPartsStates={setDocumentPartsStates}
            />
        },
        {
            header: intl.formatMessage({ id: 'DocGenDocumentGeneration.header' }),
            content: <DocGenDocumentGeneration documentPartsStates={documentPartsStates} downloadLink={documentUrl} setDownloadLink={setDocumentUrl}
                selectedDocType={selectedDocType} guidelinesDocumentTypes={guidelinesDocumentTypes} />
        },
        {
            header: intl.formatMessage({ id: 'DocGenDocumentCheck.header' }),
            content: <DocGenDocumentCheck downloadLink={documentUrl} guidelines={guidelines} documentGaps={documentGaps || []} setDocumentGaps={setDocumentGaps} />
        }
    ];

    return (
        <div className='doc-gen-main-panel'>
            <DocGenAccordion items={accordionItems} />
        </div>
    );
};

export default ModuleDocumentGenerate;


// MainMenu.tsx
import React from 'react';
import './MainMenu.css';
import { FormattedMessage } from 'react-intl';
import firebaseApp from '../../firebase/firebaseApp';

interface LegalMenuProps {
	onMenuClick: (menuItem: string) => void;
	activeMenuItem: string; // Prop to handle the active menu item
}

const LegalMenu: React.FC<LegalMenuProps> = ({ onMenuClick, activeMenuItem }) => {

	//console.log('App running: ' + firebaseApp.appName);

	return (
		<div className="legal-menu btn-group" role="group" aria-label="Legal Menu">
			<button
				onClick={() => onMenuClick('menuLegalGeneral')}
				className={`btn btn-secondary ${activeMenuItem === 'menuLegalGeneral' ? 'active' : ''}`}
			>
				<FormattedMessage id="menu.general_project_details" />
			</button>

			{firebaseApp.appName == 'app' ?
				<button
					onClick={() => onMenuClick('menuDocumentsSearch')}
					className={`btn btn-secondary ${activeMenuItem === 'menuDocumentsSearch' ? 'active' : ''}`}
				>
					<FormattedMessage id="menu.search_documents" />
				</button>
				: <></>}

			{firebaseApp.appName == 'legal' ?
				<>
					<button
						onClick={() => onMenuClick('menuJudgementsSearch')}
						className={`btn btn-secondary ${activeMenuItem === 'menuJudgementsSearch' ? 'active' : ''}`}
					>
						<FormattedMessage id="menu.search_judgements" />
					</button>
					<button
						onClick={() => onMenuClick('menuJudgementsSelected')}
						className={`btn btn-secondary ${activeMenuItem === 'menuJudgementsSelected' ? 'active' : ''}`}
					>
						<FormattedMessage id="menu.judgements_selected" />
					</button>
					<button
						onClick={() => onMenuClick('menuLawsSearch')}
						className={`btn btn-secondary ${activeMenuItem === 'menuLawsSearch' ? 'active' : ''}`}
					>
						<FormattedMessage id="menu.search_laws" />
					</button>
				</>
				: <></>}


			{firebaseApp.appName == 'legal' ?
				<button
					onClick={() => onMenuClick('menuDocBuild')}
					className={`btn btn-secondary ${activeMenuItem === 'menuDocBuild' ? 'active' : ''}`}
				>
					<FormattedMessage id="menu.build_document" />
				</button>
				: <></>
			}

			{firebaseApp.appName == 'app' || firebaseApp.appName == 'legal' ?
				<button
					onClick={() => onMenuClick('menuChat')}
					className={`btn btn-secondary ${activeMenuItem === 'menuChat' ? 'active' : ''}`}
				>
					<FormattedMessage id="menu.chat" />
				</button>
				: <></>
			}


			{/* <button
        onClick={() => onMenuClick('menuLegalDocGeneration')}
        className={`btn btn-secondary ${activeMenuItem === 'menuLegalDocGeneration' ? 'active' : ''}`}
      >
        יצירת מסמכים
      </button>
      <button
        onClick={() => onMenuClick('menuLegalDocReview')}
        className={`btn btn-secondary ${activeMenuItem === 'menuLegalDocReview' ? 'active' : ''}`}
      >
        בדיקת מסמכים
      </button>
      <button
        onClick={() => onMenuClick('menuLegalAssistant')}
        className={`btn btn-secondary ${activeMenuItem === 'menuLegalAssistant' ? 'active' : ''}`}
      >
        התייעצות עם Wisanna
      </button> */}
		</div>
	);
};

export default LegalMenu;

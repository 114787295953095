// DocGenContentInput.tsx
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Collapse, Card, CardBody } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { FaPlus, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { firestoreDocGenDeleteInputText, firestoreDocGenLoadInputTexts, firestoreDocGenSaveInputText } from '../../firebase/firebaseCaching';
import { useSafeAuth } from '../../firebase/AuthProvider';

export interface TextInput {
    id: string;
    content: string;
    isOpen: boolean;
    textareaHeight: string;
    rows: number;
    dateCreated?: number;
    dateLastUpdated?: number;
}

interface DocGenContentInputProps {
    textInputs: TextInput[];
    setTextInputs: React.Dispatch<React.SetStateAction<TextInput[]>>;
    includeProjectSummary: boolean;
    setIncludeProjectSummary: React.Dispatch<React.SetStateAction<boolean>>;
    includeMarkedJudgements: boolean;
    setIncludeMarkedJudgements: React.Dispatch<React.SetStateAction<boolean>>;
}


const DocGenContentInput: React.FC<DocGenContentInputProps> = ({ textInputs, setTextInputs, includeProjectSummary, setIncludeProjectSummary,
    includeMarkedJudgements, setIncludeMarkedJudgements
}) => {
    const intl = useIntl();
    const { authState } = useSafeAuth();

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [textInputToDeleteId, SetTextInputToDeleteId] = useState<string>("");


    const addTextInput = () => {
        setTextInputs([...textInputs, { id: uuidv4(), dateCreated: Date.now(), content: '', isOpen: true, rows: 1, textareaHeight: "auto" }]);
    };

    const addQuotesFromMarkedJudgements = () => {
    }

    const removeTextInput = (id: string) => {
        SetTextInputToDeleteId(id);
        setShowDeleteModal(true);
    };

    const handleConfirmDeleteInputText = (id: string) => {
        setShowDeleteModal(false);
        setTextInputs(textInputs.filter(input => input.id !== id));
        let input = textInputs.find(input => input.id === id);
        if (!!input) {
            firestoreDocGenDeleteInputText(authState?.uid || '', authState?.project || '', input);
        }
    };

    const updateTextInput = (id: string, content: string, e: any) => {
        let input = textInputs.find(input => input.id === id);
        if (!!input) {
            handleTextareaHeight(e, input);
            let newInput = { ...input, content, dateLastUpdated: Date.now() }
            setTextInputs(textInputs.map(input => input.id === id ? newInput : input));
            firestoreDocGenSaveInputText(authState?.uid || '', authState?.project || '', newInput);
        }
    };

    const toggleCollapse = (id: string) => {
        setTextInputs(textInputs.map(input => input.id === id ? { ...input, isOpen: !input.isOpen } : input));
    };

    const handleTextareaHeight = (event: any, input: TextInput) => {
        const textareaLineHeight = 24; // Approximate line height of the textarea
        //const previousRows = event.target.rows;
        const previousRows = input.rows;
        //event.target.rows = 1; // Reset number of rows in textarea to calculate the new height
        input.rows = 1;
        const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            //event.target.rows = currentRows;
            input.rows = currentRows;
        }

        if (currentRows >= 10) {
            event.target.style.overflowY = "scroll";
            //setTextareaHeight(`${10 * textareaLineHeight}px`);
            input.textareaHeight = `${10 * textareaLineHeight}px`;
            //event.target.rows = 10;
            input.rows = 10;
        } else {
            event.target.style.overflowY = "hidden";
            //setTextareaHeight("auto");
            input.textareaHeight = "auto";
            //event.target.rows = currentRows;
            input.rows = currentRows;
        }
    };

    const formatPreviewText = (text: string) => {
        const trimmedText = text.replace(/\n+/g, '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0').trim();
        return trimmedText.length > 100 ? `${trimmedText.substring(0, 100)}...` : trimmedText;
    };


    return (
        <div>
            <Form>
                <FormGroup check>
                    <div className='check-box-area'>
                        <Input
                            type="checkbox"
                            checked={includeProjectSummary}
                            onChange={e => setIncludeProjectSummary(e.target.checked)}
                        />
                        <label><FormattedMessage id="DocGenContentInput.includeProjectSummaryInfo" /> </label>
                    </div>
                </FormGroup>
                {/* <FormGroup check>
                    <div className='check-box-area'>
                        <Input
                            type="checkbox"
                            checked={includeMarkedJudgements}
                            onChange={e => setIncludeMarkedJudgements(e.target.checked)}
                        />
                        <label><FormattedMessage id="DocGenContentInput.includeMarkedJudgements" /> </label>
                    </div>
                </FormGroup> */}
                <div className="doc-gen-button-group">
                    <Button color="primary" className='doc-gen-btn-add-text' onClick={addTextInput}>
                        <FormattedMessage id="DocGenContentInput.addTextArea" />
                    </Button>
                    <Button color="secondary" className='doc-gen-btn-add-quotes' onClick={addQuotesFromMarkedJudgements} disabled>
                        <FormattedMessage id="DocGenContentInput.addQuotesFromMarkedJudgements" />
                    </Button>
                </div>
                {textInputs.map((input, index) => (
                    // <Card key={input.id} className="my-2">
                    //     <CardBody>
                    <div key={input.id} className='doc-gen-text-input-panel'>
                        <Button onClick={() => removeTextInput(input.id)}>
                            <FaTrash />
                        </Button>
                        <Button onClick={() => toggleCollapse(input.id)}>
                            {input.isOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </Button>
                        {input.isOpen ?
                            <Input
                                type="textarea"
                                style={{ height: input.textareaHeight, overflowY: input.rows >= 10 ? 'scroll' : 'hidden' }}
                                rows={input.rows}
                                value={input.content}
                                onChange={e => updateTextInput(input.id, e.target.value, e)}
                                placeholder={intl.formatMessage({ id: "DocGenContentInput.writeContentHere" })}
                                className="doc-gen-text-input-textarea"
                            />
                            : <></>
                        }
                        {!input.isOpen && (
                            <Label className="doc-gen-text-preview">
                                {formatPreviewText(input.content)}
                            </Label>
                        )}
                    </div>
                    //     </CardBody>
                    // </Card>
                ))}
            </Form>


            {/* Delete Input Text */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered backdrop="static"
                className={authState.currentLocale === 'he' ? 'rtl' : 'ltr'}>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="DocGenContentInput.DeleteTextInput_title" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="DocGenContentInput.DeleteTextInput_confirmation" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        <FormattedMessage id="DocGenContentInput.DeleteTextInput_cancel" />
                    </Button>
                    <Button variant="danger" onClick={() => handleConfirmDeleteInputText(textInputToDeleteId)}>
                        <FormattedMessage id="DocGenContentInput.DeleteTextInput_confirm" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DocGenContentInput;

// src/components/Login/Login.tsx

import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import firebaseApp from '../../firebase/firebaseApp';
import { FaGoogle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import './Login.css';
import logo from '../../images/logo.png';
import { svcRequestPasswordReset } from '../../Services/servicesWrapper';
import waitIcon from '../../images/loading_spinner.gif';


const Login = () => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  const handleLogin = () => {
    const auth = getAuth(firebaseApp.instance);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Login successful
      })
      .catch(() => {
        setError(intl.formatMessage({ id: 'login.failedLogin' }));
      });
  };

  const handleGoogleLogin = () => {
    const auth = getAuth(firebaseApp.instance);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        // Google login successful
      })
      .catch((error) => {
        setError(intl.formatMessage({ id: 'login.failedGoogleLogin' }, { error: error.message }));
      });
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError(intl.formatMessage({ id: 'login.enterEmail' }));
      return;
    } else {
      setError(null);
    }

    setIsLoading(true);

    try {
      let res = await svcRequestPasswordReset(email);
      if (res) {
        setResetEmailSent(true);
      } else {
        setError(intl.formatMessage({ id: 'login.failedResetEmail' }));
      }
    } catch (error) {
      setError(intl.formatMessage({ id: 'login.failedResetEmail' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={'login-container ' + (intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 'rtl' : 'ltr')}

    >
      <img src={logo} alt="Company Logo" className="legal-logo" />
      <h1 className="login-legal-title"><FormattedMessage id="login.title" /></h1>

      <div className="login-header-container">
        <h5><FormattedMessage id="login.loginHeader" /></h5>
      </div>
      {error && <p className="text-danger">{error}</p>}
      {resetEmailSent && <p className="text-success"><FormattedMessage id="login.resetEmailSent" /></p>}
      <div className="form-group group-email">
        <label className='label-email'><FormattedMessage id="login.email" /></label>
        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="form-group group-password">
        <label className='label-password'><FormattedMessage id="login.password" /></label>
        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <button className="btn btn-primary login-button" onClick={handleLogin}><FormattedMessage id="login.loginButton" /></button>

      <div className="forgot-password-container">
        <button className="btn btn-link forgot-password" onClick={handleForgotPassword} disabled={isLoading}>
          <FormattedMessage id="login.forgotPassword" />
        </button>
        {isLoading && (
          <div className="loading-icon-container">
            <img src={waitIcon} alt="Loading" className="icon-change-password-loading" />
          </div>
        )}
      </div>

      <button className="btn google-button" onClick={handleGoogleLogin}>
        <FaGoogle className="google-icon" />
        <FormattedMessage id="login.googleLogin" />
      </button>
    </div >
  );
};

export default Login;
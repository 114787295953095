// JudgementsContext.tsx
import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { IDoc } from '../DocumentSearch/DocumentTypes';
import { firestoreLoadMarkedJudgements, firestoreSaveMarkedJudgement } from '../../firebase/firebaseCaching';
import { useSafeAuth } from '../../firebase/AuthProvider';

interface JudgementsContextType {
	selectedJudgements: IDoc[];
	setSelectedJudgements: React.Dispatch<React.SetStateAction<IDoc[]>>;
}

const defaultState: JudgementsContextType = {
	selectedJudgements: [],
	setSelectedJudgements: () => { },
};

const JudgementsContext = createContext<JudgementsContextType>(defaultState);

interface Props {
	children: ReactNode;
}

export const JudgementsProvider: React.FC<Props> = ({ children }) => {
	const { authState } = useSafeAuth();
	const [selectedJudgements, setSelectedJudgements] = useState<IDoc[]>([]);

	// Effect to load selectedJudgements from Firestore when the component mounts
	useEffect(() => {
		const loadSelectedJudgements = async () => {
			const judgements = await firestoreLoadMarkedJudgements(authState?.uid || '', authState?.project || '');
			if (judgements) {
				setSelectedJudgements(judgements);
			}
		};

		loadSelectedJudgements();
	}, []);

	// Effect to save selectedJudgements to Firestore when they are updated
	// useEffect(() => {
	//   const saveSelectedJudgements = async () => {
	//     // Note: Depending on your Firestore structure, you might need to adjust the logic,
	//     // e.g., save the judgments one by one or as an entire array
	//     for (const judgement of selectedJudgements) {
	//       await firestoreSaveSelectedJudgement(authState?.uid || '', authState?.project || '', judgement);
	//     }
	//   };

	//   saveSelectedJudgements();
	// }, [selectedJudgements]);


	return (
		<JudgementsContext.Provider value={{ selectedJudgements, setSelectedJudgements }}>
			{children}
		</JudgementsContext.Provider>
	);
};

export default JudgementsContext;

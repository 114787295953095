// DocumentSearch.tsx
import React, { useState, useContext, useEffect, useRef } from 'react';
import './DocumentSearch.css';
import { IDoc, IResults } from './DocumentTypes'
import loading_spinner from '../../images/loading_spinner.gif'
import { persistInProject } from "../../firebase/persistState";
import { useSafeAuth, useSearchSubject } from '../../firebase/AuthProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { svcLoadGeometriesWithRelevance, svcSearchDoc } from '../../Services/servicesWrapper';
import JudgementsContext from '../LegalModuleJudgements/JudgementsContext';
import { firestoreSaveMarkedJudgement } from '../../firebase/firebaseCaching';
import firebaseApp from '../../firebase/firebaseApp';


type ColumnDefinition = {
	id: string;
	title: string;
	render: (doc: DocumentType) => React.ReactNode; // you can replace DocumentType with the type of your 'doc' object
};

interface DocumentSearchProps {
	onDocumentSelect: (doc: IDoc | null) => void;
	onUserQueryChange?: (adoptedQuery: string, searchType: string) => void;
	ParentId: string;
	resultsColumns: ColumnDefinition[];
}

const emptyDoc: IDoc = { url: "", desc: "", score: 0, piecesWithGeometry: null, metaData: {} };


const DocumentSearch: React.FC<DocumentSearchProps> = ({ onDocumentSelect, onUserQueryChange, ParentId, resultsColumns }) => {
	const intl = useIntl();
	// const [searchType, setSearchType] = useState<string>('approximate');

	const { authState } = useSafeAuth();
	const [searchType, setSearchType] = useState('ai');
	//const [searchType, setSearchType] = persistInProject<string>('approximate', authState?.uid || '', authState?.project || '', (ParentId ? ParentId + '.' : '') + "DocumentSearch.searchType");
	const [inputQuery, setInputQuery] = persistInProject<string>('', authState?.uid || '', authState?.project || '', (ParentId ? ParentId + '.' : '') + "DocumentSearch.inputQuery");
	const [historyQueries, setHistoryQueries] = persistInProject<string[]>([], authState?.uid || '', authState?.project || '', (ParentId ? ParentId + '.' : '') + "DocumentSearch.historyQueries");
	const [isAutoAdaptEnabled, setAutoAdaptEnabled] = persistInProject<boolean>(false, authState?.uid || '', authState?.project || '', (ParentId ? ParentId + '.' : '') + "DocumentSearch.isAutoAdaptEnabled");
	//const [isRankByMeaningEnabled, setIsRankByMeaningEnabled] = persistInProject<boolean>(true, authState?.uid || '', authState?.project || '', (ParentId ? ParentId + '.' : '') + "DocumentSearch.isRankByMeaningEnabled");
	const [selectedDoc, setSelectedDoc] = useState<IDoc | null>(null);
	const [adoptedQuery, setAdoptedQuery] = useState<string | null>('');
	const [isLoading, setIsLoading] = useState(false);
	const [results, setResults] = useState<any[]>([]); // You can replace 'any' with your results type

	const { selectedJudgements, setSelectedJudgements } = useContext(JudgementsContext);


	// ============== Auto Search Logic ==============
	const searchParamsRef = useRef<{ query: string, searchType: string } | null>(null);
	const [autoSearchInitiated, setAutoSearchInitiated] = useState(false);

	useEffect(() => {
		if (ParentId == 'Judgements') {
			const subscription = useSearchSubject().subscribe(searchParams => {
				if (searchParams) {
					searchParamsRef.current = searchParams;
					setInputQuery(searchParams.query);
					setSearchType(searchParams.searchType);
					setAutoSearchInitiated(true);
				}
			});

			return () => subscription.unsubscribe();
		}
	}, []);

	useEffect(() => {
		if (autoSearchInitiated && searchParamsRef.current) {
			const { query, searchType } = searchParamsRef.current;
			if (inputQuery === query && searchType === searchType) {
				handleSearchClick();
				searchParamsRef.current = null;
			}
		}
	}, [autoSearchInitiated]);
	// ==============================================



	const handleSearchTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchType(e.target.value);
		if (e.target.value == 'exact') {
			setAutoAdaptEnabled(false);
		}
	};
	//const handleRankByMeaningChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsRankByMeaningEnabled(e.target.checked); };
	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => { setInputQuery(e.target.value); };
	const handleAutoAdaptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAutoAdaptEnabled(e.target.checked);
		if (e.target.checked && searchType == 'exact') {
			setSearchType('approximate');
		}
	};

	const handleDocumentSelect = async (doc: IDoc) => {
		if (!doc.piecesWithGeometry) {
			svcLoadGeometriesWithRelevance(authState, adoptedQuery || '', doc.url, ParentId).then((value) => {
				const updatedDoc = { ...doc, piecesWithGeometry: value };
				updatedDoc.piecesWithGeometry = value;
				onDocumentSelect(updatedDoc);
			});
		}
		setSelectedDoc(doc); // Update the state variable with the selected document
		onDocumentSelect(doc);
	};


	const handleAdoptedUserQueryChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		// if (onUserQueryChange) {
		// 	onUserQueryChange(e.target.value || '', searchType || '');
		// }
	}


	const maxHistory = 15;
	// Inside the handleSearchClick function, parse the results
	const handleSearchClick = async () => {
		setIsLoading(true);
		if (inputQuery) {
			if (historyQueries) {
				const withoutCurrent = historyQueries.filter(q => q !== inputQuery && q);
				// Prepend
				setHistoryQueries(['', inputQuery, ...withoutCurrent.slice(0, maxHistory - 1)]);
			} else {
				setHistoryQueries(['', inputQuery]);
			}
		}

		setAdoptedQuery('');
		setResults([]);
		handleDocumentSelect(emptyDoc);

		const sQuery = inputQuery || '';//(document.getElementById('inputQuery') as HTMLTextAreaElement).value;
		let searchOption = searchType || 'ai';//(document.querySelector('input[name="searchType"]:checked') as HTMLInputElement).value;
		if (searchOption === 'approximate_w_meaning') searchOption = 'approximate';
		//const searchOptionRankByMeaning = isRankByMeaningEnabled || false;//(document.querySelector('input[name="chkRankByMeaning"]') as HTMLInputElement).checked;
		const rankByMeaning = searchType === 'approximate_w_meaning';


		try {
			//const response = await svcSearchDoc(authState, sQuery, searchOption, searchOptionRankByMeaning, isAutoAdaptEnabled || false, ParentId);
			const response = await svcSearchDoc(authState, sQuery, searchOption, rankByMeaning, isAutoAdaptEnabled || false, ParentId);

			const results: IResults = response;
			setAdoptedQuery(results.used_query || '');
			if (onUserQueryChange) onUserQueryChange(results.used_query || '', searchType || '');
			results.results.forEach(parseDesc);
			setResults(results.results);
			if (results.results.length > 0) {
				const firstDoc = results.results[0];
				handleDocumentSelect(firstDoc);
			}

			if (autoSearchInitiated) {
				setSearchType('approximate');
				setAutoSearchInitiated(false);
			}

		} catch (error) {
			console.log('Search failed.');
		}
		setIsLoading(false);
	};

	const MAX_CHARS_TO_SHOW = 100;
	if (!resultsColumns) return;


	const checkIsMarked = (doc: IDoc) => {
		// Attempt to find the document by URL
		const found = selectedJudgements.find((judgement) => judgement.url === doc.url);

		// Check both if the document is found and if its isSelected property is true
		return !!found && found.isMarked;
	};


	// const markIsSelected = (doc: IDoc, isSelected: boolean) => {
	// 	if (isSelected) {
	// 		selectedJudgements.find((judgement) => judgement.url === doc.url) ? null : setSelectedJudgements([...selectedJudgements, doc]);
	// 	} else {
	// 		selectedJudgements.find((judgement) => judgement.url === doc.url) ? setSelectedJudgements(selectedJudgements.filter((judgement) => judgement.url !== doc.url)) : null;
	// 	}
	// };

	const markJudjement = (doc: IDoc, isMarked: boolean) => {
		if (isMarked) doc.adoptedQuery = adoptedQuery || '';

		const index = selectedJudgements.findIndex((judgement) => judgement.url === doc.url);

		if (index !== -1) {
			// Judgement found, update its isSelected status
			const updatedJudgements = [...selectedJudgements];
			updatedJudgements[index] = { ...updatedJudgements[index], isMarked };
			setSelectedJudgements(updatedJudgements);
			// Firestore update logic here for the individual judgement
			firestoreSaveMarkedJudgement(authState?.uid || '', authState?.project || '', updatedJudgements[index]);
		} else {
			// New judgement, add to the array and mark as selected or unselected
			const newJudgement = { ...doc, isMarked };
			setSelectedJudgements([...selectedJudgements, newJudgement]);
			// Firestore update logic here for the new judgement
			firestoreSaveMarkedJudgement(authState?.uid || '', authState?.project || '', newJudgement);
		}
	};

	const handleQuickSearchClick = (searchOption: string) => (e: React.MouseEvent) => {
		e.preventDefault();

		const formatDate = (date: Date): string => {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		};

		switch (searchOption) {
			case 'recentJudgements':
				//const yesterday = new Date();
				//yesterday.setDate(yesterday.getDate() - 1);
				//setInputQuery(`{פסיקה אחרונה}`);
				//handleSearchClick();
				authState.initiateSearch(`{פסיקה אחרונה}`, 'approximate');
				break;
			case 'recentJudgementsSupreme':
				//const yesterday = new Date();
				//yesterday.setDate(yesterday.getDate() - 1);
				//setInputQuery(`{פסיקה אחרונה בג"ץ}`);
				//handleSearchClick();
				authState.initiateSearch(`{פסיקה אחרונה עליון}`, 'approximate');
				break;
			case 'judgementsOfJudge':
				// Implement the logic for judgements of a judge search
				setInputQuery(`שופט:`);
				break;
			default:
				console.warn('Unknown search option:', searchOption);
		}
	}


	return (
		<div className="container legal-search-container">
			<div className="label-link-container">
				<label htmlFor="inputQuery" className="form-label">
					<FormattedMessage id="documentSearch.inputQueryLabel" />
				</label>
				{firebaseApp.appName === 'legal' && ParentId == 'Judgements' && (
					<div className="quick-search-links">
						<a href="#" onClick={handleQuickSearchClick('recentJudgements')}>
							<FormattedMessage id="documentSearch.recentJudgements" />
						</a>
						<a href="#" onClick={handleQuickSearchClick('recentJudgementsSupreme')}>
							<FormattedMessage id="documentSearch.recentJudgementsSupreme" />
						</a>
						{/* <a href="#" onClick={handleQuickSearchClick('judgementsOfJudge')}>
							<FormattedMessage id="documentSearch.judgementsOfJudge" />
						</a> */}
					</div>
				)}
			</div>
			<div className="input-group mb-3 search-container">
				<textarea className="form-control custom-textarea" rows={3} value={inputQuery || ''} onChange={handleInputChange} disabled={isLoading} />

				{isLoading ? (
					<img className="loading" src={loading_spinner} alt="Loading..." />
				) : (
					<div className="search-activation-container input-group-append" id="search-activation-container">

						<select className="form-select history-menu"
							onChange={(e) => setInputQuery(e.target.value)}
						>
							{/* {(historyQueries || []).map(query => (
								<option className="option-historic-query" key={query} value={query}>
									{query.length <= MAX_CHARS_TO_SHOW ? query : query.slice(0, MAX_CHARS_TO_SHOW) + '...'}
								</option>
							))} */}
							{(historyQueries || []).map((query, index) => (
								<option className={index ? "option-historic-query" : "option-historic-query-title"} key={query} value={query}>
									{`${index ? index + '. ' : ''}${query.length <= MAX_CHARS_TO_SHOW ?
										index ? query : intl.formatMessage({ id: 'documentSearch.historyOfSearches' }) :
										query.slice(0, MAX_CHARS_TO_SHOW) + '...'}`}
								</option>
							))}
						</select>

						<button className="btn btn-primary" id="btnSearch" onClick={handleSearchClick} disabled={!inputQuery || isLoading}>
							<FormattedMessage id="documentSearch.searchButton" />
						</button>
					</div>
				)}
			</div>

			<div className="options-container">
				<label className="form-label">
					<FormattedMessage id="documentSearch.searchTypeLabel" />
				</label>

				<div className="form-check">
					<label className="form-check-label">
						<input className="form-check-input1" type="radio" name={`searchType-${ParentId}`} value="ai" checked={searchType === 'ai'} onChange={handleSearchTypeChange} />
						<FormattedMessage id="documentSearch.searchWithAI" />
					</label>
				</div>
				<div className="form-check">
					<label className="form-check-label make-inline">
						<input className="form-check-input1" type="radio" name={`searchType-${ParentId}`} value="approximate" checked={searchType == 'approximate'} onChange={handleSearchTypeChange} />
						<FormattedMessage id="documentSearch.searchByQuery" />
					</label>
					{/* <label className="form-check-label make-inline container-chkRankByMeaning" htmlFor="chkRankByMeaning">
						<input className="form-check-input1" type="checkbox" name="chkRankByMeaning" id="chkRankByMeaning" checked={isRankByMeaningEnabled || false} onChange={handleRankByMeaningChange} />
						<FormattedMessage id="documentSearch.rankByMeaning" />
					</label> */}
				</div>
				<div className="form-check">
					<label className="form-check-label">
						<input className="form-check-input1" type="radio" name={`searchType-${ParentId}`} value="meaning" checked={searchType == 'meaning'} onChange={handleSearchTypeChange} />
						<FormattedMessage id="documentSearch.searchByMeaning" />
					</label>
				</div>
				<div className="form-check">
					<label className="form-check-label">
						<input className="form-check-input1" type="radio" name={`searchType-${ParentId}`} value="approximate_w_meaning" checked={searchType === 'approximate_w_meaning'} onChange={handleSearchTypeChange} />
						<FormattedMessage id="documentSearch.approximateWithMeaning" />
					</label>
				</div>
				<div className="form-check">
					<label className="form-check-label">
						<input className="form-check-input1" type="radio" name={`searchType-${ParentId}`} value="exact" checked={searchType == 'exact'} onChange={handleSearchTypeChange} />
						<FormattedMessage id="documentSearch.exactSearch" />
					</label>
				</div>

			</div>

			<div className="form-check chkAutoAdaptQuery-container">
				<label className="form-check-label" htmlFor="chkAutoAdaptQuery">
					<input
						className="form-check-input"
						type="checkbox"
						id="chkAutoAdaptQuery"
						onChange={handleAutoAdaptChange}
						checked={isAutoAdaptEnabled || false}
					/>
					<FormattedMessage id="documentSearch.allowAutoAdaptation" />
				</label>
			</div>
			<div className="lblQuery-container">
				<label htmlFor="lblQuery" className="form-label"><FormattedMessage id="documentSearch.activatedQueryLabel" /></label>
				<textarea className="form-control custom-textarea" id="lblQuery" rows={3} onChange={handleAdoptedUserQueryChanged} value={adoptedQuery || ''} readOnly disabled={true} />
			</div>

			<div className="results-container">
				<label className="form-label">        <FormattedMessage id="documentSearch.searchResultsLabel" /></label>

				<table className="table table-hover table-striped1" id="tableSearchResults">
					<thead className="thead-secondary">
						<tr>
							{resultsColumns.map((col, idx) => (
								<th key={idx}
									className={
										col.id === 'caseNumber' ? 'column-case-number' : (
											col.id === 'relevance' ? 'column-relevance' : (
												col.id === 'index' ? 'column-index' : (
													col.id === 'is_selected' ? 'column-is-selected' : '')))}
								>{col.title}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{results.map((doc, index) => (
							<tr key={index} onClick={() => handleDocumentSelect(doc)} className={selectedDoc === doc ? 'table-primary' : ''}>
								{resultsColumns.map((col, colIdx) => {
									if (col.id === 'is_selected') {
										// Render a checkbox in the is_selected column
										return (
											<td key={colIdx}>
												<input
													type="checkbox"
													checked={checkIsMarked(doc)}
													onChange={(e) => markJudjement(doc, e.target.checked)}
												/>
											</td>
										);
									} else {
										// Render other columns as before
										return (
											<td key={colIdx} className={
												col.id === 'caseNumber' ? 'column-case-number' : (
													col.id === 'relevance' ? 'column-relevance' : (
														col.id === 'index' ? 'column-index' : ''))
											}>{col.id === 'index' ? index + 1 : col.render(doc)}</td>
										);
									}
								})}
							</tr>
						))}
					</tbody>
				</table>

			</div>
		</div>
	);
};

export default DocumentSearch;



// Function to parse the description of each doc
const parseDesc = (doc: IDoc) => {
	// Split the description by semicolons
	const descParts = doc.desc.split(';');
	// Overview is the text before the first semicolon
	doc.overview = descParts[0].trim();
	doc.metaData = {};
	// Iterate over the other parts and assign them to the doc object
	for (let i = 1; i < descParts.length; i++) {
		const [key, ...valueArr] = descParts[i].split(':');
		let value = valueArr.join(':').trim();
		if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
			value = value.substring(0, 16).replace('T', ' ');
		}
		// Removing leading and trailing whitespaces and new lines
		const cleanedKey = key?.replace(/^[\s\n]+|[\s\n]+$/g, '');
		// Only update properties that exist in IDoc and are not 'score' or 'piecesWithGeometry'
		// if (cleanedKey == 'piecesWithGeometry') {
		// if (cleanedKey !== 'piecesWithGeometry') {

		doc.metaData[cleanedKey] = value || '';
		if (doc.metaData[cleanedKey] == 'undefined') doc.metaData[cleanedKey] = '';
		//}
	}
};



// ChartRenderer.tsx
import React from 'react';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { Line, Bar, Pie, Doughnut, Radar, PolarArea, Bubble, Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend, RadialLinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import './ChartRenderer.css'; // Import the CSS file

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend, ChartDataLabels, annotationPlugin);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    annotationPlugin
);

export interface ChartConfig {
    type: ChartType;
    data: ChartData;
    options: ChartOptions;
}

interface ChartRendererProps {
    config: ChartConfig;
}

const ChartRenderer: React.FC<ChartRendererProps> = ({ config }) => {
    const chartComponents: { [key in ChartType]?: React.ElementType } = {
        line: Line,
        bar: Bar,
        pie: Pie,
        doughnut: Doughnut,
        radar: Radar,
        polarArea: PolarArea,
        bubble: Bubble,
        scatter: Scatter
    };

    const ChartComponent = chartComponents[config.type] || Bar; // Default to Bar chart

    if (!ChartComponent) {
        console.error(`Unsupported chart type: ${config.type}`);
        return <pre>{JSON.stringify({ config }, null, 2)}</pre>;
    }

    try {
        return <ChartComponent data={config.data} options={config.options} />;
    } catch (error) {
        console.error('Error rendering chart:', error);
        return <pre>{JSON.stringify({ config }, null, 2)}</pre>;
    }
};


export default ChartRenderer;

// AuthProvider.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, getAuth } from 'firebase/auth';
import firebaseApp from './firebaseApp';
import { fetchProjectsListFromFirebase, fetchSelectedProjectFromFirebase, updateSelectedProjectToFirebase, updateProjectToFirebase } from './firebaseWrapper';
import Main from '../Main';
import Login from './../components/Login/Login';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BusAssistant from '../components/BusAssistant/BusAssistant';
import { BehaviorSubject } from 'rxjs';
import { svcUsageLog_login } from '../Services/servicesWrapper';
import XpedITeAIAssistant from '../components/XpedITeAIAssistant/XpedITeAIAssistant';

export interface AuthState {
	app: string;
	user: User | null;
	uid: string | null;
	email: string | null;
	project: string;
	projects: { id: string, name: string, date_last_modified: any }[];
	currentLocale: string;
	messagesForCurrentLocale: {};
	concurrentConnectionsByUser_isWithinAllowedLimit: boolean;
	allowedFeatures: string[];
	initiateSearch: (query: string, searchType: string) => void;
}

const searchSubject = new BehaviorSubject<{ query: string, searchType: string } | null>(null);


interface AuthContextProps {
	authState: AuthState;
	createNewProject: (projectId: string) => void;
	selectProject: (projectId: string) => void;
	refreshProjectsList: () => void;
	updateProjectDateModified: (projectId: string) => void;
	switchLanguage: (locale: string) => void;
	initiateSearch: (query: string, searchType: string) => void;
	allowFeature: (feature: string) => void;
}

//const DEFAULT_PROJECT = { id: 'default_project', name: '', date_last_modified: null };

const DEFAULT_AUTH = {
	app: 'generic',
	user: null,
	uid: null,
	email: null,
	project: 'default_project',
	projects: [],
	currentLocale: '' || (firebaseApp.appName == 'legal' ? 'he' : 'en'),
	messagesForCurrentLocale: {},
	allowedFeatures: [],
	concurrentConnectionsByUser_isWithinAllowedLimit: true,
	initiateSearch: (query: string, searchType: string) => { }
};
//console.log('1:' + firebaseApp.appName + '; ' + DEFAULT_AUTH.currentLocale);

export const AuthContext = createContext<AuthContextProps | null>(null);
//export const AuthContext = createContext<AuthState | null>(null);
export const useAuth = () => useContext(AuthContext);
export const useSafeAuth = (): AuthContextProps => {
	const context = useAuth();
	if (!context) {
		return {
			authState: DEFAULT_AUTH,
			createNewProject: () => { },  // Default function that does nothing
			selectProject: () => { },     // Default function that does nothing
			refreshProjectsList: () => { },
			updateProjectDateModified: (projectId: string) => { },
			switchLanguage: (locale: string) => { },
			initiateSearch: (query: string, searchType: string) => { },
			allowFeature: (feature: string) => { }
		};
	}
	return context;
};

interface AuthProviderProps {
	children: React.ReactNode;
}

const auth = getAuth(firebaseApp.instance);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [authState, setAuthState] = useState<AuthState>(DEFAULT_AUTH);

	const [loading, setLoading] = useState(true);
	const [isDataFetched, setIsDataFetched] = useState(false);  // For data fetching 

	const initiateSearch = (query: string, searchType: string) => {
		searchSubject.next({ query, searchType });
	};

	//const [stateProjects, setStateProjects] = useState<string[]>(['default_project']);
	//const [stateProject, setStateProject] = useState<string>('default_project');


	const updateProjectDateModified = async (projectId: string) => {
		if (authState.uid) {
			await updateProjectToFirebase(authState.uid, projectId, '', null);
		}
	}

	const refreshProjectsList = async () => {
		if (authState.uid) {
			const projects = await fetchProjectsListFromFirebase(authState.uid);
			setAuthState(prev => ({
				...prev,
				projects: projects || []
			}));
		}
	}

	const createNewProject = async (projectId: string) => {
		if (authState.uid) {
			setAuthState(prev => ({
				...prev,
				project: projectId,
				//projects: [...prev.projects, {...DEFAULT_PROJECT, projectId}]
			}));
			//setLoading(true);
			setIsDataFetched(false);
			await updateProjectDateModified(projectId);

			await updateSelectedProjectToFirebase(authState.uid, projectId);

			//await invalidateProjectsListCache(authState.uid);
			await refreshProjectsList();
			//setAuthState(prev => ({ ...prev, project: projectId }));
		}
	};

	const switchLanguage = async (locale: string) => {
		setAuthState(prev => ({
			...prev,
			currentLocale: locale,
		}));
	}
	const allowFeature = (feature: string) => {
		//console.log('Feature allowed: ' + feature);
		if (!!feature && !authState.allowedFeatures.includes(feature)) {
			setAuthState(prev => ({
				...prev,
				allowedFeatures: [...prev.allowedFeatures, feature]
			}));
		}
	}

	const selectProject = async (projectId: string) => {
		if (authState.uid) {
			//setLoading(true);
			setIsDataFetched(false);

			await updateProjectDateModified(projectId);

			await updateSelectedProjectToFirebase(authState.uid, projectId);
			setAuthState(prev => ({ ...prev, project: projectId }));
			//Optionally, if you want to refresh persistedProjects when a project is opened:
			// const newPersistedProjects = await fetchStateFromFirebase(authState.uid || '', 'default_project', "Auth.projects");
			// if (newPersistedProjects) {
			//     setPersistedProjects(newPersistedProjects);
			// }
		}
	};

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
			if (authUser) {
				const uid = await authUser.uid;
				const email = authUser.email || null;
				console.log(firebaseApp.appName + '; ' + DEFAULT_AUTH.currentLocale + '; ' + email);
				setAuthState(prev => ({
					...prev,
					user: authUser,
					uid,
					email,
					// Temporarily keep the default project until data is fetched
					project: 'default_project',
					projects: [],
					initiateSearch
				}));
			} else {
				setAuthState(prev => ({
					...prev,
					user: null,
					uid: null,
					email: null,
					project: 'default_project',
					projects: [],
					initiateSearch
				}));
				setLoading(false);
			}
		});

		return () => unsubscribe();
	}, [auth]);

	useEffect(() => {
		if (authState.uid) {
			// Fetch the initial state of the selected project and list of projects
			const fetchData = async () => {
				if (authState.uid) {
					const project = await fetchSelectedProjectFromFirebase(authState.uid);
					//const projects = await fetchProjectsListFromFirebase(authState.uid);
					setAuthState(prev => ({
						...prev,
						project: project || 'default_project',
						//projects: projects || []
					}));
					if (!project) {
						selectProject(authState.project);
					}
					//setStateProject(authState.project);
					//setStateProjects(projects);
					setLoading(false);
					svcUsageLog_login(authState);
				}
			};

			fetchData();
			// switchLanguage('he');
		}
	}, [authState.uid]);

	useEffect(() => {
		if (authState.uid && !loading) {
			if (authState.projects && authState.project) {
				setIsDataFetched(true);
			}
		}
	}, [authState, loading]);


	function flattenMessages(nestedMessages: any, prefix = '') {
		return Object.keys(nestedMessages).reduce((messages: any, key) => {
			const value = nestedMessages[key];
			const prefixedKey = prefix ? `${prefix}.${key}` : key;

			if (typeof value === 'string') {
				messages[prefixedKey] = value;
			} else {
				Object.assign(messages, flattenMessages(value, prefixedKey));
			}

			return messages;
		}, {});
	}

	useEffect(() => {
		function loadLocaleData(locale: string) {
			//console.log('2:' + firebaseApp.appName + '; ' + locale);
			switch (locale) {
				case 'he':
					return import('../locales/he.json');
				case 'ro':
					return import('../locales/ro.json');
				case 'en':
				default:
					return import('../locales/en.json');
			}
		}
		loadLocaleData(authState.currentLocale).then((messages) => {
			setAuthState(prev => ({
				...prev,
				messagesForCurrentLocale: flattenMessages(messages.default),
			}));
		});
	}, [authState.currentLocale]);

	if (loading) {//} || !.) {
		return <div>Loading...</div>;
	}

	// if (isDataFetched) {
	// 	console.log(authState.messagesForCurrentLocale);
	// }

	return (
		<AuthContext.Provider key={authState.project} value={{ authState, createNewProject, selectProject, refreshProjectsList, updateProjectDateModified, switchLanguage, initiateSearch, allowFeature }}>
			<BrowserRouter>
				<Routes>
					<Route
						path="/busadvisor"
						element={
							<IntlProvider locale={authState.currentLocale} messages={authState.messagesForCurrentLocale}>
								<BusAssistant />
							</IntlProvider>
						}
					/>
					<Route
						path="/xpedite-ai-analysis"
						element={
							<IntlProvider locale={authState.currentLocale} messages={authState.messagesForCurrentLocale}>
								<XpedITeAIAssistant />
							</IntlProvider>
						}
					/>
					<Route
						path="*" // Match any other paths
						element={
							isDataFetched ? (
								<IntlProvider locale={authState.currentLocale} messages={authState.messagesForCurrentLocale}>
									<Main />
								</IntlProvider>
							) : (
								<IntlProvider locale={authState.currentLocale} messages={authState.messagesForCurrentLocale}>
									<Login />
								</IntlProvider>
							)
						}
					/>
				</Routes>
			</BrowserRouter>
		</AuthContext.Provider>
		// <AuthContext.Provider key={authState.project} value={{ authState, createNewProject, selectProject, refreshProjectsList, updateProjectDateModified, switchLanguage }}>

		// 	{(isDataFetched) ?
		// 		<IntlProvider locale={authState.currentLocale} messages={authState.messagesForCurrentLocale}>
		// 			<Main />
		// 		</IntlProvider> :
		// 		<Login />}

		// </AuthContext.Provider>
	);
};

export const useSearchSubject = () => {
	return searchSubject;
};
import { AuthState } from "../../firebase/AuthProvider";
import { fetchProjectFromFirebase } from "../../firebase/firebaseWrapper";

export async function extractProjectInfo(authState: AuthState) {
    let projectInfo = {
        "projectName": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.projectName'),
        "plaintiff": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.plaintiff'),
        "defendant": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.defendant'),
        "description": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.description'),
        "caseNumbers": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.caseNumbers'),
        "court": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.court'),
        "additionalInfo": await fetchProjectFromFirebase(authState.uid || '', authState.project, 'General.additionalInfo')
    };
    return projectInfo;
}
// persistState.ts
import { useState, useEffect } from "react";
import { fetchProjectFromFirebase, updateProjectToFirebase, fetchAccountFromFirebase, updateAccountToFirebase } from "./firebaseWrapper";


// ====================== persistInProject ========================

export const persistInProject = <T>(
	initialValue: T,
	userId: string,
	project: string,
	stateKey: string
): [T, React.Dispatch<React.SetStateAction<T>>] => {
	const [state, setState] = useState<T>(initialValue);
	const [isLoaded, setIsLoaded] = useState(false);

	// Only for fetching the initial state
	useEffect(() => {
		const fetchState = async () => {
			if (userId && !isLoaded) {  // Fetch only if not already loaded
				let initialState: T | null = await fetchProjectFromFirebase(userId, project, stateKey);
				setState(initialState !== null ? initialState : initialValue);
				setIsLoaded(true);
			}
		};
		fetchState();
	}, [userId, stateKey, initialValue, project]);  // Removed `isLoaded` from dependency array

	// Only for updating the project state to Firebase
	useEffect(() => {
		if (isLoaded) {
			updateProjectToFirebase(userId, project, stateKey, state);
		}
	}, [state, userId, stateKey, project]);

	return [state, setState];
};

// ====================== persistInAccount ========================

export const persistInAccount = <T>(
	initialValue: T,
	userId: string,
	stateKey: string
): [T | null, React.Dispatch<React.SetStateAction<T | null>>] => {
	const [state, setState] = useState<T | null>(null);
	const [isLoaded, setIsLoaded] = useState(false);

	// Fetch the initial state for account
	useEffect(() => {
		const fetchState = async () => {
			if (userId && !isLoaded) {  // Fetch only if not already loaded
				let initialState: T | null = await fetchAccountFromFirebase(userId, stateKey);
				setState(initialState !== null ? initialState : initialValue);
				setIsLoaded(true);
			}
		};
		fetchState();
	}, [userId, stateKey, initialValue]);  // Removed `isLoaded` from dependency array

	// Update the state to Firebase for account
	useEffect(() => {
		if (isLoaded) {
			updateAccountToFirebase(userId, stateKey, state);
		}
	}, [state, userId, stateKey]);

	return [state, setState];
};

// firebaseHelpers.tsx

import { collection, getDocs } from 'firebase/firestore';
import db from './firestoreDb';
import { fetchData, updateDataToFirebase } from './firebaseCaching';


export const fetchAccountFromFirebase = async (userId: string, stateKey: string): Promise<any> => {
	const accountData = await fetchData(userId, 'user');
	return accountData ? accountData[stateKey] || null : null;
};

export const updateAccountToFirebase = async (userId: string, stateKey: string, state: any) => {
	if (!stateKey) { stateKey = 'date_last_modified'; }

	const currentState = await fetchAccountFromFirebase(userId, stateKey);
	if (stateKey == 'date_last_modified' || ((JSON.stringify(currentState) !== JSON.stringify(state)) && (currentState || state))) {
		const dataToUpdate = { [stateKey]: state };
		await updateDataToFirebase(userId, 'user', dataToUpdate);
	}
};

export const fetchProjectFromFirebase = async (userId: string, project: string, stateKey: string): Promise<any> => {
	const projectData = await fetchData(userId, 'projects', project);
	return projectData ? projectData[stateKey] || null : null;
};

export const updateProjectToFirebase = async (userId: string, project: string, stateKey: string, state: any) => {
	if (!stateKey) { stateKey = 'date_last_modified'; }

	const currentState = await fetchProjectFromFirebase(userId, project, stateKey);
	if (stateKey == 'date_last_modified' || ((JSON.stringify(currentState) !== JSON.stringify(state)) && (currentState || state))) {
		const dataToUpdate = { [stateKey]: state };
		await updateDataToFirebase(userId, 'projects', dataToUpdate, project);
	}
};

export const fetchSelectedProjectFromFirebase = async (userId: string): Promise<string | null> => {
	const userData = await fetchData(userId, 'user');
	return userData ? userData.selectedProject || null : null;
};

export const updateSelectedProjectToFirebase = async (userId: string, selectedProject: string): Promise<void> => {
	const currentSelectedProject = await fetchSelectedProjectFromFirebase(userId);
	if (currentSelectedProject !== selectedProject) {
		await updateDataToFirebase(userId, 'user', { selectedProject });
	}
};

// Do not cache projects list - always retrieve from firestore
export const fetchProjectsListFromFirebase = async (userId: string): Promise<{ id: string; name: string; date_last_modified: any }[]> => {
	const projectsCollection = collection(db, 'users', userId, 'projects');
	const querySnapshot = await getDocs(projectsCollection);
	const projects: { id: string; name: string; date_last_modified: any }[] = [];
	querySnapshot.forEach((doc) => {
		const data = doc.data();
		projects.push({
			id: doc.id,
			name: data['General.projectName'] || doc.id,
			date_last_modified: data['date_last_modified']
		});
	});

	// Sorting projects by date_last_modified in descending order
	projects.sort((a, b) => b.date_last_modified - a.date_last_modified);

	return projects;
};

// Main.tsx
import React, { useEffect, useState } from 'react';
import './Main.css';
import Header from './components/Header/Header';
import MainMenu from './components/MainMenu/MainMenu';
import LegalModuleJudgements from './components/LegalModuleJudgements/LegalModuleJudgements';
import LegalModuleJudgementsMarked from './components/LegalModuleJudgementsMarked/LegalModuleJudgementsMarked.tsx';
import LegalModuleLaws from './components/LegalModuleLaws/LegalModuleLaws';
import ModuleGeneral from './components/ModuleGeneral/ModuleGeneral';
import { useSafeAuth } from './firebase/AuthProvider';
import { persistInAccount } from "./firebase/persistState";
import ModuleDocumentsSearch from './components/ModuleDocumentsSearch/ModuleDocumentsSearch';
import ModuleDocumentBuild from './components/ModuleDocumentBuild/ModuleDocumentBuild';
import firebaseApp, { currentApp } from './firebase/firebaseApp';
import ModuleChat from './components/ModuleChat/ModuleChat.tsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { JudgementsProvider } from './components/LegalModuleJudgements/JudgementsContext.js';
import ModuleDocumentGenerate from './components/ModuleDocumentGenerate/ModuleDocumentGenerate.tsx';


//import { clearLocalCache } from './firebase/firebaseHelpers';
// import LegalModuleChat from './components/LegalModuleChat/LegalModuleChat';

const Main: React.FC = () => {

	let isLegal = currentApp === "legal";
	const intl = useIntl();


	// useEffect(() => {
	//   // Clear the cache when the app component mounts
	//   clearLocalCache();
	// }, []);


	const { authState, switchLanguage } = useSafeAuth();
	const [selectedModule, setSelectedModule] = persistInAccount<string>(isLegal ? 'searchJudgements' : 'searchDocuments', authState?.uid || '', 'Legal.selectedModule');
	const [activeMenuItem, setActiveMenuItem] = persistInAccount<string>(isLegal ? 'menuJudgementsSearch' : 'menuDocumentsSearch', authState?.uid || '', 'Legal.activeMenuItem');
	const [selectedLanguage, setSelectedLanguage] = persistInAccount<string>(firebaseApp.appName == 'legal' ? 'he' : 'en', authState?.uid || '', 'Legal.selectedLanguage');
	//const [selectedModule, setSelectedModule] = useState<'general' | 'searchJudgements' | 'searchLaws' | 'generation' | 'review' | 'assistant'>('searchJudgements');
	//const [activeMenuItem, setActiveMenuItem] = useState('menuJudgementsSearch');

	// Define the title mapping for the menu items
	// const menuTitles: Record<string, string> = {
	// 	menuLegalGeneral: 'כללי - פרטי פרוייקט',
	// 	menuJudgementsSearch: 'חיפוש פסקי דין',
	// 	menuLawsSearch: 'חיפוש חקיקה',
	// 	menuLegalDocGeneration: 'יצירת מסמכים',
	// 	menuLegalDocReview: 'בדיקת מסמכים',
	// 	menuLegalAssistant: 'התייעצות עם Wisanna',
	// };

	function setDocumentTitle(title: string) {
		document.title = title;
	}
	useEffect(() => {
		// If the current app is 'legal', change the title to 'Legal App',
		// otherwise, change it to 'Regular App' (Change these titles as per your requirement)
		setDocumentTitle(isLegal ? intl.formatMessage({ id: 'global.title_legal' }) : intl.formatMessage({ id: 'global.title_app' }));
	}, []);


	useEffect(() => {
		if (selectedLanguage && selectedLanguage != authState.currentLocale) {
			switchLanguage(selectedLanguage);
		}
	}, [selectedLanguage]);

	useEffect(() => {
		if (authState.currentLocale && selectedLanguage != authState.currentLocale) {
			setSelectedLanguage(authState.currentLocale);
		}
	}, [authState.currentLocale]);

	// Initial title set to the first menu item's title
	//const [title, setTitle] = useState(menuTitles[activeMenuItem || '']);

	const handleMenuClick = (menuItem: string) => {
		setActiveMenuItem(menuItem);
		//setTitle(menuTitles[menuItem]); // Set the title based on the clicked menu item
		switch (menuItem) {
			case 'menuLegalGeneral':
				setSelectedModule('general');
				break;
			case 'menuJudgementsSearch':
				setSelectedModule('searchJudgements');
				break;
			case 'menuJudgementsSelected':
				setSelectedModule('selectedJudgements');
				break;
			case 'menuLawsSearch':
				setSelectedModule('searchLaws');
				break;
			case 'menuDocumentsSearch':
				setSelectedModule('searchDocuments');
				break;
			case 'menuDocBuild':
				setSelectedModule('buildDocument');
				break;
			case 'menuLegalDocReview':
				setSelectedModule('review');
				break;
			case 'menuChat':
				setSelectedModule('chat');
				break;
			default:
				break;
		}
	};

	if (!authState.uid || !authState.project) return (<></>);

	const direction = authState.currentLocale === 'he' ? 'rtl' : 'ltr';

	return (
		<div className={"legal container-fluid " + direction}>
			<Header title={''} logo="/src/images/logo.png" />
			<MainMenu onMenuClick={handleMenuClick} activeMenuItem={activeMenuItem || ''} />

			<div className="legal-module row">
				<div className={[selectedModule === 'general' ? 'show' : 'hide', 'module-element'].join(" ")}>
					<ModuleGeneral />
				</div>
				<JudgementsProvider>
					<div className={[(selectedModule || 'searchJudgements') === 'searchJudgements' ? 'show' : 'hide', 'module-element'].join(" ")}>
						<LegalModuleJudgements />
					</div>
					<div className={[(selectedModule || 'selectedJudgements') === 'selectedJudgements' ? 'show' : 'hide', 'module-element'].join(" ")}>
						<LegalModuleJudgementsMarked />
					</div>
				</JudgementsProvider>
				<div className={[selectedModule === 'searchLaws' ? 'show' : 'hide', 'module-element'].join(" ")}>
					<LegalModuleLaws />
				</div>
				<div className={[selectedModule === 'searchDocuments' ? 'show' : 'hide', 'module-element'].join(" ")}>
					<ModuleDocumentsSearch />
				</div>
				<div className={[selectedModule === 'buildDocument' ? 'show' : 'hide', 'module-element'].join(" ")}>
					<ModuleDocumentGenerate />
					{/* <ModuleDocumentBuild /> */}
				</div>
				<div className={[selectedModule === 'chat' ? 'show' : 'hide', 'module-element'].join(" ")}>
					<ModuleChat />
				</div>




				{/* <div className={selectedModule === 'assistant' ? 'show' : 'hide'}>
                <LegalModuleChat />
            </div> */}
			</div>
		</div>
	);
}

export default Main;


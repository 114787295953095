// DocGenDocumentGeneration.tsx
import React, { useState } from 'react';
import './DocGenDocumentGeneration.css';
import waitIcon from '../../images/loading_spinner.gif';
import { DocumentPartState } from './DocGenDocumentPartsCreation';
import { svcBuildDocFromTemplate } from '../../Services/servicesWrapper';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { FormattedMessage } from 'react-intl';
import { GuidelinesDocumentType } from './ModuleDocumentGenerate';

type DocGenDocumentPartsCreationProps = {
    documentPartsStates: { [part: string]: DocumentPartState };
    downloadLink: string;
    setDownloadLink: React.Dispatch<React.SetStateAction<string>>;
    selectedDocType: string;
    guidelinesDocumentTypes: GuidelinesDocumentType[];
};
type ContentMapping = { [part: string]: string };

const DocGenDocumentGeneration: React.FC<DocGenDocumentPartsCreationProps> = ({ documentPartsStates, downloadLink, setDownloadLink, selectedDocType, guidelinesDocumentTypes }) => {
    const { authState } = useSafeAuth();
    const [isGenerating, setIsGenerating] = useState(false);
    //const [downloadLink, setDownloadLink] = useState('');
    const currentLocale = authState.currentLocale || 'en'

    const templates = ['Statement of Claims - template1.docx'];
    const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);

    const findDocumentParts = (locale: string) => {
        const docType = guidelinesDocumentTypes.find(type => type.id === selectedDocType);
        return docType?.documentParts[locale] || [];
    };

    const handleGenerateDocument = async () => {
        setIsGenerating(true);

        let docPartsEn = findDocumentParts("en");
        let docPartsLocal = findDocumentParts(currentLocale);

        // Create a mapping between local parts and English parts
        const localToEnMap = docPartsLocal.reduce((acc: ContentMapping, localPart, index) => {
            const enPart = docPartsEn[index];
            if (enPart) {
                acc[localPart] = enPart;
            }
            return acc;
        }, {});

        // Map the local parts to English parts, taking content from documentPartsStates
        const docParts = Object.keys(documentPartsStates).reduce((acc, localKey) => {
            const enKey = localToEnMap[localKey];
            if (enKey) {
                acc[enKey] = documentPartsStates[localKey].content;
            }
            return acc;
        }, {} as ContentMapping);

        // const docParts = Object.keys(documentPartsStates).reduce((acc: ContentMapping, part) => {
        //     acc[part] = documentPartsStates[part].content;
        //     return acc;
        // }, {} as ContentMapping);

        try {
            let docUrl = await svcBuildDocFromTemplate(authState, selectedTemplate, docParts);
            setDownloadLink(docUrl);
        }
        catch (error) {
            console.log(error);
            setDownloadLink('');
        }
        finally { setIsGenerating(false); }
    };

    return (
        <div className="doc-gen-generation">
            <div className="row mb-3 ">
                <div className="col-auto">
                    <button
                        className="btn btn-primary"
                        onClick={handleGenerateDocument}
                    >
                        <FormattedMessage id="DocGenDocumentGeneration.documentGeneration" />
                    </button>
                </div>
                <div className="col">
                    <select
                        className="form-select"
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                    >
                        {templates.map((template, index) => (
                            <option key={index} value={template}>
                                {template}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {isGenerating && (
                <div className="row justify-content-center progress-panel">
                    <div className="generation-status d-flex align-items-center mb-3">
                        <img src={waitIcon} alt="Loading..." className="me-2" />
                        <span><FormattedMessage id="DocGenDocumentGeneration.generatingDocument" /></span>
                    </div>
                </div>
            )}
            {!isGenerating && downloadLink && (
                <div className="row justify-content-center download-panel">
                    <div className="download-link mb-3">
                        <a href={downloadLink} className="btn btn-success" download>
                            <FormattedMessage id="DocGenDocumentGeneration.downloadDoacument" />
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocGenDocumentGeneration;

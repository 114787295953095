// firestoreDb.tsx
import firebaseApp from './firebaseApp'
import { getFirestore } from "firebase/firestore";

const db = getFirestore(firebaseApp.instance);

export const clearFirestoreCache = async () => {
  const dbName = "firebase-firestore"; // This is the default name Firestore uses for its IndexedDB

  return new Promise((resolve, reject) => {
    const request = window.indexedDB.deleteDatabase(dbName);

    request.onerror = function (event) {
      console.error("Error deleting Firestore cache:", event);
      reject(event);
    };

    request.onsuccess = function (event) {
      console.log("Firestore cache deleted successfully");
      resolve(event);
    };
  });
};


export default db;
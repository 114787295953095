// DocGenDocumentPartsCreation.tsx
import React, { useEffect, useState } from 'react';
import { useSafeAuth } from '../../firebase/AuthProvider';
import waitIcon from '../../images/loading_spinner.gif';
import { svcTranslateContent, svcWriteDocPart } from '../../Services/servicesWrapper';
import { TextInput } from './DocGenContentInput';
import { extractProjectInfo } from './DocGenCommon';
import './DocGenDocumentPartsCreation.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { GuidelinesDocumentType } from './ModuleDocumentGenerate';

type DocGenDocumentPartsCreationProps = {
    selectedDocType: string;
    guidelinesDocumentTypes: GuidelinesDocumentType[];// { locale: string; documentParts: string[] }[]; //{ id: string; documentParts: string[] }[];
    includeProjectSummary: boolean;
    includeMarkedJudgements: boolean;
    textInputs: TextInput[];
    guidelines: string;
    guidelinesEn: string;
    documentPartsStates: { [part: string]: DocumentPartState };
    setDocumentPartsStates: React.Dispatch<React.SetStateAction<{ [part: string]: DocumentPartState }>>;
};

export type DocumentPartState = {
    isCreating: boolean;
    progressStatus: string;
    content: string;
};

const DocGenDocumentPartsCreation: React.FC<DocGenDocumentPartsCreationProps> = ({
    selectedDocType,
    guidelinesDocumentTypes,
    includeProjectSummary, includeMarkedJudgements, textInputs,
    guidelines, guidelinesEn,
    documentPartsStates, setDocumentPartsStates
}) => {
    const intl = useIntl();
    const { authState } = useSafeAuth();
    const currentLocale = authState.currentLocale || 'en'
    const [isComponentLoadingComplete, setIsComponentLoadingComplete] = useState(false);

    const findDocumentParts = () => {
        const docType = guidelinesDocumentTypes.find(type => type.id === selectedDocType);
        return docType?.documentParts[currentLocale] || [];
    };

    useEffect(() => {
        if (!isComponentLoadingComplete && Object.keys(documentPartsStates).length > 0) {
            const updatedStates = Object.entries(documentPartsStates).reduce((acc, [part, state]) => ({
                ...acc,
                [part]: { ...state, isCreating: false, progressStatus: '' }
            }), {});

            setDocumentPartsStates(updatedStates);
            setIsComponentLoadingComplete(true);
        }
    }, [documentPartsStates]);

    const handleTextAreaChange = (part: string, content: string) => {
        setDocumentPartsStates(prevState => ({
            ...prevState,
            [part]: {
                ...prevState[part],
                content: content,
            },
        }));
    };

    const handleStartOver = () => {
        setDocumentPartsStates({});
    };

    const MAX_CHARS_FOR_TRANSLATION = 1600;

    const handleCreatePart = async (part: string) => {
        setStatus(part, true, intl.formatMessage({ id: 'DocGenDocumentPartsCreation.processing' }), null);

        try {
            const projectInfo = await extractProjectInfo(authState);
            const contentPieces = [...[JSON.stringify(projectInfo)], ...textInputs.map(input => input.content)];

            const contentEn = await svcWriteDocPart(authState, part, contentPieces, guidelinesEn);
            if (!!contentEn) {
                let content = contentEn;

                if (currentLocale !== 'en') {
                    setStatus(part, true, intl.formatMessage({ id: 'DocGenDocumentPartsCreation.writing' }), null);
                    let targetLang = currentLocale === 'he' ? 'Hebrew' : currentLocale;

                    // Split the content into chunks based on MAX_CHARS_FOR_TRANSLATION
                    const contentChunks = splitContentByNewline(contentEn, MAX_CHARS_FOR_TRANSLATION);

                    // Translate each chunk and concatenate
                    content = '';
                    for (const chunk of contentChunks) {
                        const translatedChunk = await svcTranslateContent(authState, chunk, targetLang, "LEGAL", !!content ? content : guidelines);
                        content += (content.length > 0 ? '\n\n' : '') + translatedChunk;
                    }
                }

                setStatus(part, false, intl.formatMessage({ id: 'DocGenDocumentPartsCreation.creationComplete' }), content);
            } else {
                setStatus(part, false, intl.formatMessage({ id: 'DocGenDocumentPartsCreation.creationComplete' }),
                    'Some error occurred while creating the part. Please try again.');
            }

        } catch (error) {
            console.error('Error creating document part:', error);
            setStatus(part, false, intl.formatMessage({ id: 'DocGenDocumentPartsCreation.error' }), 'Error occurred while creating the document part.');
        }
    };

    function setStatus(part: string, isCreating: boolean, status: string, content: string | null) {
        setDocumentPartsStates(prevState => {
            const newContent = (content !== undefined && content !== null) ? content : prevState[part]?.content || '';
            return {
                ...prevState,
                [part]: { isCreating, progressStatus: status, content: newContent }
            }
        });
    }

    // Function to split the content into chunks by new lines, respecting the max character limit
    function splitContentByNewline(content: string, maxChars: number) {
        const lines = content.split('\n');
        const chunks = [];
        let currentChunk = '';

        lines.forEach(line => {
            if (currentChunk.length + line.length > maxChars) {
                chunks.push(currentChunk);
                currentChunk = line;
            } else {
                currentChunk += (currentChunk.length > 0 ? '\n' : '') + line;
            }
        });

        if (currentChunk.length > 0) {
            chunks.push(currentChunk);
        }

        return chunks;
    }


    const documentParts = findDocumentParts();

    return (
        <div className="doc-gen-document-parts-creation">
            <button
                className="btn btn-primary"
                onClick={handleStartOver}
            >
                <FormattedMessage id="DocGenDocumentPartsCreation.startOver" />
            </button>
            <ul className="list-group">
                {documentParts.map((part, index) => (
                    <li key={index} className="list-group-item">
                        <div className="part-creation-row">
                            <button
                                onClick={() => handleCreatePart(part)}
                                className="btn btn-primary btn-generate-doc-part"
                                disabled={documentPartsStates[part]?.isCreating}
                            >
                                <FormattedMessage id="DocGenDocumentPartsCreation.create" /> {part}
                            </button>
                            {documentPartsStates[part]?.isCreating && (
                                <div className="creation-status">
                                    <img src={waitIcon} alt="Loading..." />
                                    <span>{documentPartsStates[part]?.progressStatus}</span>
                                </div>
                            )}
                        </div>
                        {documentPartsStates[part] && !documentPartsStates[part].isCreating && (

                            <textarea
                                className="form-control content-area"
                                value={documentPartsStates[part]?.content || ''}
                                onChange={(e) => handleTextAreaChange(part, e.target.value)}
                                rows={Math.min(10, (documentPartsStates[part]?.content.match(/\n/g) || []).length + 1)}
                            />

                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DocGenDocumentPartsCreation;

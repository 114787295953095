// BusAssistant.tsx

import styles from "./BusAssistant.module.css";
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { firestoreLoadChat, firestoreSaveChat, firestoreLoadChatMessages } from '../../firebase/firebaseCaching';
import { Chat, ChatMessage } from '../BaseChatElements/chatUtils';
import BaseChatComponent from '../BaseChatElements/BaseChatComponent';
import busIcon from '../../images/wisanna_icon.png';

function BusAssistant() {
    const intl = useIntl();
    const { authState } = useSafeAuth();
    const [chat, setChat] = useState<Chat | null>(null);
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [location, setLocation] = useState({ latitude: 0, longitude: 0 });


    const webChatId = authState.uid ? `buschat_${authState.uid}` : 'default_buschat'; // Unique chat ID per user

    useEffect(() => {
        if (!location.latitude && !location.longitude) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                () => {
                    console.log('Unable to retrieve your location');
                }
            );
        }

        const loadChatAndMessages = async () => {
            let loadedChat = await firestoreLoadChat(authState.uid || '', authState.project || '', webChatId);

            // Create a new chat if it doesn't exist
            if (!loadedChat) {
                loadedChat = {
                    id: webChatId,
                    title: intl.formatMessage({ id: 'chat.webChatTitle' }), // Or any default title
                    last_updated: Date.now(),
                };
            }

            // Load messages
            const messages = await firestoreLoadChatMessages(authState.uid || '', authState.project || '', loadedChat.id);
            messages.sort((a, b) => a.date - b.date); // Sort by date

            setChat(loadedChat);
            setChatMessages(messages);
        };

        if (authState.uid && authState.project) { loadChatAndMessages(); }

    }, []);

    // Update the chat state whenever messages change 
    useEffect(() => {
        if (chat && chatMessages.length > 0) {
            const updatedChat = { ...chat, last_updated: Date.now() };
            setChat(updatedChat);
            firestoreSaveChat(authState.uid || '', authState.project || '', updatedChat);
        }
    }, [chatMessages]);

    if (chat) {
        //debugger;
    }

    // Since there's only one chat, pass it directly to BaseChatComponent
    return (
        <div className={styles.webChatContainer}>
            <div className={styles.header}>
                <div className={styles.headerIcon}>
                    <img src={busIcon} alt="Bus Icon" />
                </div>
                <div className={styles.headerTitle}>JJ Kavanagh Customer Service</div> {/* Centered title */}
            </div>

            <div className={styles.chatArea}>
                {chat && (
                    <BaseChatComponent
                        currentChat={chat}
                        setCurrentChat={setChat}
                        chatMessages={chatMessages}
                        setChatMessages={setChatMessages}
                        considerAddChatTitle={() => { }} // Simplified, as title functionality might not be needed
                        instanceType="BusAssistant"
                    />
                )}
            </div>
        </div>
    );
}

export default BusAssistant;
// LegalModuleJudgements.tsx
import { useState, useContext } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import DocumentSearch from '../DocumentSearch/DocumentSearch';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import styles from '../UIGeneral/styles.module.css';
import './LegalModuleJudgements.css';
import { IDoc } from '../DocumentSearch/DocumentTypes'
import { useIntl } from 'react-intl';



function LegalModuleJudgements() {
  const intl = useIntl();
  const [selectedDoc, setSelectedDoc] = useState<IDoc | null>(null);
  const [userQuery, setUserQuery] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('');



  // Handle document selection
  const handleDocumentSelect = (doc: IDoc | null) => {
    setSelectedDoc(doc);
  };
  const onUserQueryChange = (adoptedQuery: string, searchType: string) => {
    setUserQuery(adoptedQuery);
    setSearchType(searchType);
  }


  const columns = [
    { id: 'is_selected', title: " ", render: (doc: any) => "" },
    { id: 'index', title: "#", render: (index: any) => index },
    { id: 'relevance', title: "@", render: (doc: any) => doc?.score },
    { id: 'caseNumber', title: intl.formatMessage({ id: "legalModuleJudgements.caseNumber" }), render: (doc: any) => doc?.metaData?.case_number },
    { id: 'decisionDate', title: intl.formatMessage({ id: "legalModuleJudgements.decisionDate" }), render: (doc: any) => doc?.metaData?.decision_date },
    {
      id: 'court', title: intl.formatMessage({ id: "legalModuleJudgements.court" }), render: (doc: any) => `${doc?.metaData?.court || ''}` +
        ((doc?.metaData?.court || '').indexOf(doc?.metaData?.area || '') >= 0 ||
          (doc?.metaData?.area || '').indexOf('undefined') >= 0 || (doc?.metaData?.court || '').indexOf('בית המשפט העליון') >= 0 ?
          '' : ` - ${doc?.metaData?.area || ''}`)
    },
    { id: 'description', title: intl.formatMessage({ id: "legalModuleJudgements.description" }), render: (doc: any) => doc?.metaData?.title || doc.overview }
  ];

  return (
    <div className={[styles.Container, "legal-module-search"].join(" ")}>
      <PanelGroup className="legal-module-panes" autoSaveId="moduleJudgementsPanesResizer" direction="horizontal">
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 2 : 1}>
          <div className={[styles.PanelContent, "legal-search"].join(" ")}>
            <DocumentSearch onDocumentSelect={handleDocumentSelect} onUserQueryChange={onUserQueryChange} ParentId="Judgements" key="SearchJudgements" resultsColumns={columns} />
          </div>
        </Panel>
        <ResizeHandle className='splitter' />
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 1 : 2}>
          <div className={[styles.PanelContent, "legal-preview"].join(" ")}>
            <DocumentPreview
              iframeUrl={selectedDoc?.url ?? ''}
              userQuery={userQuery}
              searchType={searchType}
              piecesWithGeometry={selectedDoc?.piecesWithGeometry}
              ParentID='Judgements'
              ContentType='Judgements'
              metaData={{
                JName: selectedDoc?.metaData?.overview_summary ?? selectedDoc?.overview ?? '',
                JNumber: selectedDoc?.metaData?.case_number ?? '',
                JJudge: selectedDoc?.metaData?.judge ?? '',
                JCourt: selectedDoc?.metaData?.court ?? '',
                JDistrict: selectedDoc?.metaData?.area ?? '',
                JDecisionDate: selectedDoc?.metaData?.decision_date ?? '',
                JType: selectedDoc?.metaData?.procedure_type ?? ''
              }}
            />
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
}

export default LegalModuleJudgements;

//Preview_JudgementsMetadata.tsx
import { FormattedMessage } from 'react-intl';
import { useSafeAuth } from '../../firebase/AuthProvider';
import './Preview_JudgementsMetadata.css';

interface Preview_JudgementsMetadataProps {
    iframeUrl: string;
    piecesWithGeometry: any;
    userQuery: string;
    searchType: string;
    metaData: Record<string, any>;
}


const Preview_JudgementsMetadata: React.FC<Preview_JudgementsMetadataProps> = ({
    iframeUrl,
    userQuery,
    searchType,
    piecesWithGeometry,
    metaData
}) => {
    const { authState } = useSafeAuth();

    const handleClickCaseNumber = (case_number: string) => {
        const regex = /[0-9\/-]/g;

        // Use the match method to extract all matching characters
        const matches = case_number.match(regex);

        let case_id = matches ? matches.join('') : '';
        authState.initiateSearch(`אזכורים:${case_id}`, 'approximate'); // or whatever search type you want
    };

    return (
        <div>
            <textarea readOnly value={metaData.JName || ''} className="form-control mb-3" id="txtJName" rows={3} />

            <div className="details-container row">
                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtJNumber">
                        <FormattedMessage id="judgementsMetadata.caseNumber" />
                    </label>
                    <input type="text" onClick={() => handleClickCaseNumber(metaData.JNumber ? `${metaData.JNumber}` : '')}
                        readOnly value={metaData.JNumber || ''} id="txtJNumber" className="form-control link-case-number" />
                    {/* {metaData.JNumber ?
                        <a onClick={() => handleClick(metaData.JNumber ? `"${metaData.JNumber}"` : '')} className="link-class case-number">{metaData.JNumber || ''}</a>
                        : ''
                    } */}
                </div>

                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtJType">
                        <FormattedMessage id="judgementsMetadata.procedureType" />
                    </label>
                    <input type="text" readOnly value={metaData.JType || ''} id="txtJType" className="form-control" />
                </div>

                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtJJudge">
                        <FormattedMessage id="judgementsMetadata.judgeComposition" />
                    </label>
                    <input type="text" readOnly value={metaData.JJudge || ''} id="txtJJudge" className="form-control" />
                </div>

                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtJCourt2">
                        <FormattedMessage id="judgementsMetadata.courts" />
                    </label>
                    <input type="text" readOnly value={metaData.JCourt || ''} id="txtJCourt2" className="form-control" />
                </div>

                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtJDistrict">
                        <FormattedMessage id="judgementsMetadata.district" />
                    </label>
                    <input type="text" readOnly value={metaData.JDistrict || ''} id="txtJDistrict" className="form-control" />
                </div>

                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtJDecisionDate">
                        <FormattedMessage id="judgementsMetadata.decisionDate" />
                    </label>
                    <input type="text" readOnly value={metaData.JDecisionDate || ''} id="txtJDecisionDate" className="form-control" />
                </div>
            </div>
        </div>
    );
}

export default Preview_JudgementsMetadata;
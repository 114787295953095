// DocGenSelectDocumentType.tsx
import React, { useState, useEffect } from 'react';
import documentTypesData from './documentTypes.json'; // assuming the JSON file is in the same directory
import { useSafeAuth } from '../../firebase/AuthProvider';
import { FormattedMessage } from 'react-intl';

// Define your DocumentType to avoid conflicts with the global DOM DocumentType
type DocumentType = {
    id: string;
    name: string;
};

type NameType = {
    en: string;
    he: string;
};

interface DocGenSelectDocumentTypeProps {
    selectedDocType: string;
    setSelectedDocType: React.Dispatch<React.SetStateAction<string>>;
}

const DocGenSelectDocumentType: React.FC<DocGenSelectDocumentTypeProps> = ({ selectedDocType, setSelectedDocType }) => {
    const { authState } = useSafeAuth();
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);

    useEffect(() => {
        const currentLocale = authState.currentLocale || 'en';
        const loadedDocumentTypes: DocumentType[] = documentTypesData.documentTypes.map(type => ({
            id: type.id,
            name: (type.name as NameType)[currentLocale as keyof NameType] || type.name.en
        }));

        setDocumentTypes(loadedDocumentTypes);

        // Automatically set the selectedDocType to the first document type if not already set
        if (loadedDocumentTypes.length > 0 && !selectedDocType) {
            setSelectedDocType(loadedDocumentTypes[0].id);
        }
    }, [authState.currentLocale, setSelectedDocType]);


    return (
        <div className="mb-3 doc-gen-doc-type-selection-pane"> {/* Bootstrap margin bottom class */}
            <label htmlFor="document-type-select" className="form-label"><FormattedMessage id="DocGenSelectDocumentType.selectDocumentType" /> </label>
            <select className="form-select doc-gen-document-type-select"
                value={selectedDocType}
                onChange={e => setSelectedDocType(e.target.value)}
            >
                {documentTypes.map((type) => (
                    <option className="doc-gen-document-type-select-option" key={type.id} value={type.id}>{type.name}</option>
                ))}
            </select>
        </div>
    );
};

export default DocGenSelectDocumentType;

// firebaseApp.ts
import { initializeApp, FirebaseApp } from "firebase/app";

import firebaseConfig_Legal from './firebaseConfig_Legal.json';
import firebaseConfig_App from './firebaseConfig_App.json';

const firebaseConfig = import.meta.env.MODE === "legal"
	? firebaseConfig_Legal
	: firebaseConfig_App;

// Initialize Firebase
const appInstance = initializeApp(firebaseConfig);

export const currentApp = import.meta.env.MODE === "legal" ? "legal" : "app";

console.log('Current App: ' + currentApp);
//console.log(import.meta.env);

export const firebaseApp: { instance: FirebaseApp, appName: string } = {
	instance: appInstance,
	appName: currentApp
};

export default firebaseApp;

// DocGenShowGuidelines.tsx
import React, { useEffect, useState } from 'react';

interface DocGenShowGuidelinesProps {
    selectedDocType: string;
    guidelines: string;
    setGuidelines: React.Dispatch<React.SetStateAction<string>>;
}

const DocGenShowGuidelines: React.FC<DocGenShowGuidelinesProps> = ({ selectedDocType, guidelines, setGuidelines }) => {

    return (
        <div>
            <textarea
                className="form-control"
                value={guidelines}
                readOnly
                style={{ height: '300px' }}
            />
        </div>
    );
};


export default DocGenShowGuidelines;

/* eslint-disable prefer-const */
// servicesWrapper.ts
import axios from 'axios';
import { AuthState } from '../firebase/AuthProvider'
//import { Auth } from 'firebase/auth';
import firebaseApp from '../firebase/firebaseApp';
import { v4 as uuidv4 } from 'uuid';



let svcDomain = 'https://wisanna-gcr-image-g7oeim6mnq-uc.a.run.app';
//let svcDomain = 'http://localhost:8006';
//let svcDomain = 'http://localhost:8030';


let svcUrl = `${svcDomain}/user_services`;
let svcUrl_changePwd = `${svcDomain}/generatePasswordResetToken`;
let svcUrl_Stream = svcUrl + '_stream';
let svcUrl_UsageLog = `${svcDomain}/usage_log`;

let requestMade_LoadIndexes = false;
let selectedDocsIndex_auth = '';

export async function downloadAsWord(u: AuthState, message_id: string, html_request: string, html_response: string | string[], lang: string) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';


        const response = await axios.post(svcUrl, {
            ...initLogData('downloadAsWord'),
            service: 'download-html-as-word',
            params: { message_id, html_request, html_response, lang },
        }, {
            headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` }
        });

        return response?.data?.url || '';

    } catch (error) {
        processAxiosError(error);
        return null;
    }
}

//export async function svcChat(user_uid: string, user_email: string, chat_id: string, prompt: string,  
export async function svcChat(u: AuthState, chat_id: string, user_msg_id: string, prompt: string,
    onWordReceived: (word: string) => void, onFinish: (userPrompt: string, completeResponse: string) => void) {

    let res = 'ok';

    if (u.uid && u.email && prompt) {
        try {
            let token = u?.user ? await u.user.getIdToken() : '';
            const response = await fetch(svcUrl_Stream, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token} ${firebaseApp.appName}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...initLogData('svcChat'), chat_id, user_msg_id, service: 'chat', query: prompt, params: {}, localtime: new Date().toISOString() })
            });

            if (!response.body) {
                throw new Error("ReadableStream not yet supported in this browser.");
            }

            const reader = response.body.getReader();
            let completeResponse = "";
            const decoder = new TextDecoder("utf-8");

            reader.read().then(function processText({ done, value }: ReadableStreamReadResult<Uint8Array>): any {
                try {
                    if (done) {
                        onFinish(prompt, completeResponse);
                        return;
                    }
                    let chunkText = decoder.decode(value);
                    completeResponse += chunkText;
                    onWordReceived(chunkText);
                    return reader.read().then(processText);
                } catch (error) {
                    console.error("Error during stream processing:", error);
                    onWordReceived('\n$E$: משהו השתבש, נא לנסות שוב.\n')
                    // Handle the error appropriately
                }
            });
        } catch (error) {
            // You might want to handle this error differently since it's no longer just Axios errors
            console.error("Error processing stream:", error);
            res = 'error';
        }
    }
    return res;
}



export function updateSelectedDocsIndex_auth(selectedIndex: string) { selectedDocsIndex_auth = selectedIndex; }

let pendingRequest: Promise<any> | null = null;
export async function svcLoadListMyIndexes(u: AuthState) {
    //console.log('svcLoadListMyIndexes1 - requestMade_LoadIndexes: ' + requestMade_LoadIndexes);
    if (u?.uid && u?.email) {
        //console.log('svcLoadListMyIndexes2 - requestMade_LoadIndexes: ' + requestMade_LoadIndexes);
        if (!requestMade_LoadIndexes) {
            requestMade_LoadIndexes = true;

            // Wrap the logic inside a new promise
            pendingRequest = new Promise((resolve, reject) => {
                (async () => {
                    try {
                        let token = u?.user ? await u.user.getIdToken() : '';
                        const response = await axios.post(svcUrl, {
                            ...initLogData('svcLoadListMyIndexes'),
                            service: 'list-my-indexes',
                            params: {},
                        }, {
                            headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` }
                        });

                        let myIndexes = response.data;
                        if (!selectedDocsIndex_auth && myIndexes?.length) {
                            selectedDocsIndex_auth = myIndexes[0]?.commands?.[0];
                        }

                        resolve(myIndexes);
                    } catch (error) {
                        processAxiosError(error);
                        reject(error);
                    }
                })();
            });


            // Return the promise
            return pendingRequest;
        } else if (pendingRequest) {
            // If a request is pending, return the pending promise
            return pendingRequest;
        }
    }
    return null;
}

export const svcUsageLog_login = async (u: AuthState) => {
    try {

        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl_UsageLog,
            {
                ...initLogData('svcUsageLog_login'),
            },
            {
                headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` }
            }
        );
        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
};


export const svcSearchDoc = async (u: AuthState, inputQuery: string, searchOption: string, searchOptionRankByMeaning: boolean, transformQuery: boolean, ParentId: string) => {
    try {

        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcSearchDoc'),
                service: ParentId == 'Judgements' ? 'search-judgements2' :
                    (ParentId == 'Laws' ? 'search-laws' : 'search-docs'),
                query: inputQuery,
                params: {
                    auth: selectedDocsIndex_auth || '',
                    qty: 100,
                    method: searchOption, // include the method in the params
                    rankByMeaning: searchOptionRankByMeaning,
                    transformQuery
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
};

export async function svcLoadGeometriesWithRelevance(u: AuthState, inputQuery: string, url: string, ParentId: string) {
    try {
        if (!url) return null;
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcLoadGeometriesWithRelevance'),
                service: 'pdf-geometries-with_relevance', query: inputQuery, params: {
                    auth: selectedDocsIndex_auth || '',
                    url: url,
                    index: ParentId == 'Judgements' ? 'judgements2' : (ParentId == 'Laws' ? 'laws' : '')
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcFeedbackAIResponse(u: AuthState, chat_id: string, corr_id: string, sentiment: 'like' | 'dislike' | 'like-remove' | 'dislike-remove') {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcFeedbackAIResponse'),
                service: 'feedback', params: {
                    chat_id, corr_id, sentiment
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcBuildTOC(u: AuthState, tocInstructions: any) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcBuildTOC'),
                service: 'build-doc-toc', params: {
                    instructions: tocInstructions
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

function getSessionId() {
    let browserSessionId = sessionStorage.getItem('browser_session_id');
    if (!sessionStorage.getItem('browser_session_id')) {
        browserSessionId = uuidv4();
        sessionStorage.setItem('browser_session_id', browserSessionId);
    }
    return browserSessionId;
}

function getDeviceId() {
    let deviceId = localStorage.getItem('wisanna_device_id');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('wisanna_device_id', deviceId);
    }
    return deviceId;
}


function initLogData(caller: string) {
    let callerName = caller;
    if (!callerName) {
        try {
            const error = new Error();
            const stack = (error?.stack || '').split('\n');
            const callerLine = stack[2]; // The caller function is typically the third line
            callerName = callerLine.trim().split(' ')[1]; // Extract the caller function name
        } catch { }
    }
    //console.log('Called by:', callerName);

    let data = {
        localtime: new Date().toISOString(),
        sessionid: getSessionId(),
        deviceid: getDeviceId(),
        url: window.location.href,
        useragent: navigator.userAgent,
        referrer: document.referrer,
        screenSize: `${window.screen.width}x${window.screen.height}`,
        language: navigator.language,
        callerName,
    }
    return data;
}

export async function svcCheckContentPiecesCoverage(u: AuthState, contentPieces: string[], requirements: string, language: string) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcCheckContentPiecesCoverage'),
                service: 'check-doc-content-coverage', params: {
                    contentPieces, requirements, language
                }
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcCheckDocumentCoverage(u: AuthState, documentUrl: string, requirements: string) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcCheckDocumentCoverage'),
                service: 'check-doc-per-requirements', params: {
                    documentUrl, requirements
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcWriteDocPart(u: AuthState, documentPart: string, contentPieces: string[], requirements: string) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcWriteDocPart'),
                service: 'write-doc-content', params: {
                    documentPart, contentPieces, requirements, purposeType: "LEGAL"
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcTranslateContent(u: AuthState, content: string, language: string, style: string, referenceStyle: string) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcTranslateContent'),
                service: 'translate-content', params: {
                    content, language, style, referenceStyle
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcBuildDocFromTemplate(u: AuthState, template: string, docParts: object) {
    try {
        let token = u?.user ? await u.user.getIdToken() : '';
        const response = await axios.post(svcUrl,
            {
                ...initLogData('svcBuildDocFromTemplate'),
                service: 'build-document-from-template', params: {
                    template, docParts
                },
            }, { headers: { 'Authorization': `Bearer ${token} ${firebaseApp.appName}` } });

        return response.data;
    } catch (error) {
        processAxiosError(error);
    }
}

export async function svcRequestPasswordReset(email: string) {
    if (!email) {
        return;
    }

    try {
        let req = {
            ...initLogData('svcRequestPasswordReset'),
            email, app_type: firebaseApp.appName
        };
        let res = (await axios.post(svcUrl_changePwd, req, {
            headers: {
                'Content-Type': 'application/json',
                // Add other headers if needed
            }
        })) as any;

        if (!res?.data?.ok) {
            throw new Error('Failed to send reset email');
        }
        return true;

    } catch (error) {
        //setError(intl.formatMessage({ id: 'login.failedResetEmail' }));
        return false;
    }
};

function processAxiosError(error: unknown) {
    console.error('An error occurred while fetching data:', error);
    if (axios.isAxiosError(error)) {
        // Check if the error response is available
        if (error.response) {
            console.error('Data:', error.response.data); // Response data sent by the server
            console.error('Status:', error.response.status); // HTTP status code
            console.error('Headers:', error.response.headers); // HTTP headers
        } else if (error.request) {
            console.error('The request was made but no response was received:', error.request);
        } else {
            console.error('Something happened in setting up the request:', error.message);
        }
    }
    throw error;
}
// DocumentPreview.tsx
import React, { useState, useEffect } from 'react';
import './DocumentPreview.css';
import PDFViewer from '../PDFViewer/PDFViewer';
import arrow_right from '../../images/icon-arrow-right.png';
import arrow_left from '../../images/icon-arrow-left.png';
import Preview_JudgementsMetadata from '../Preview_JudgementsMetadata/Preview_JudgementsMetadata';
import Preview_LawsMetadata from '../Preview_LawsMetadata/Preview_LawsMetadata';
import Preview_DocumentsMetadata from '../Preview_DocumentsMetadata/Preview_DocumentsMetadata';
import { useIntl, FormattedMessage } from 'react-intl';


// import loading_spinner from '../../images/loading_spinner.gif'
//import { AggregatedText } from '../PDFViewer/extractPDFText';

interface LegalPreviewProps {
	iframeUrl: string;
	piecesWithGeometry: any;
	userQuery: string;
	searchType: string;
	metaData: Record<string, any>;
	ParentID: string;
	ContentType: string;
}

const DocumentPreview: React.FC<LegalPreviewProps> = ({
	iframeUrl,
	userQuery,
	searchType,
	piecesWithGeometry,
	metaData,
	ParentID,
	ContentType
}) => {
	const intl = useIntl();
	//const [isLoadingRelevanceMarkup, setIsLoadingRelevanceMarkup] = useState(false);
	const [searchString, setSearchString] = useState<string>("");
	const [activeSearchText, setActiveSearchText] = useState<string>("");
	const [shouldMarkText] = useState(false);
	const [prevAnnotation, setPrevAnnotation] = useState<number>(0);
	const [nextAnnotation, setNextAnnotation] = useState<number>(0);


	useEffect(() => {
		if (iframeUrl && iframeUrl.startsWith('http')) {
			handleFindClick();
		}
		setNextAnnotation(0);
		setPrevAnnotation(0);
	}, [iframeUrl]);  // <-- run whenever iframeUrl changes

	// useEffect(() => {
	// 	if (iframeUrl && iframeUrl.startsWith('http') && searchType == 'exact' && !userQuery) {
	// 		setSearchString('');
	// 	}
	// }, [userQuery, searchType]);  // <-- run whenever iframeUrl changes

	useEffect(() => {
		if (iframeUrl && iframeUrl.startsWith('http') && searchType == 'exact') {
			setSearchString(userQuery);
		}
	}, [searchType]);  // <-- run whenever iframeUrl changes

	useEffect(() => {
		if (iframeUrl && iframeUrl.startsWith('http') && searchType == 'exact' && searchString?.length) {
			//handleFindClick();
		}
	}, [searchType, searchString]);  // <-- run whenever iframeUrl changes


	// const handleAddMarkupClick = async () => {
	//   setIsLoadingRelevanceMarkup(true);
	//   setShouldMarkText(true);  // Trigger the marking process
	// };

	const handleNextAnnotationClick = async () => {
		setNextAnnotation(nextAnnotation + 1);
	}
	const handlePrevAnnotationClick = async () => {
		setPrevAnnotation(prevAnnotation + 1);
	}

	function reverseString(str: string) {
		return str.split('').reverse().join('');
	}
	const handleFindClick = async () => {
		setActiveSearchText(reverseString(searchString));
	};

	//const proxyUrl = "https://us-central1-wisanna.cloudfunctions.net/wisanna-proxy";
	//const encodedIframeUrl = encodeURIComponent(iframeUrl);
	//const proxiedIframeUrl = `${proxyUrl}?url=${encodedIframeUrl}`;
	//const proxiedIframeUrl = `${encodedIframeUrl}`;
	const shouldShowPDFViewer = iframeUrl && iframeUrl.startsWith('http');


	// const handleMarkingCompleted = () => {
	//   setShouldMarkText(false);
	//   setIsLoadingRelevanceMarkup(false);
	// };

	return (
		<div className="legal-preview-container container">
			{
				ParentID == "MarkedJudgements" &&
				<div className='query-panel'>
					<label htmlFor="txtQuery"><FormattedMessage id="documentPreview.query" /></label>
					<span className="query-panel-spacer" />
					<textarea readOnly value={userQuery || ''} className="form-control mb-3 doc-preview-query" id="txtQuery" />
				</div>
			}

			{
				ContentType === 'Judgements'
					? <Preview_JudgementsMetadata {...{ metaData, iframeUrl, piecesWithGeometry, userQuery, searchType }} />
					: (ContentType === 'Laws' ?
						<Preview_LawsMetadata {...{ metaData, iframeUrl, piecesWithGeometry, userQuery, searchType }} />
						: <Preview_DocumentsMetadata {...{ metaData, iframeUrl, piecesWithGeometry, userQuery, searchType }} />
					)
			}

			<div className="btn-container">
				<div>
					<button className="btn-annotation-browse btn btn-primary" onClick={handlePrevAnnotationClick}>
						<img className="image-inside-button" src={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? arrow_right : arrow_left} alt="Prev" />
					</button>
					<button className="btn-annotation-browse btn btn-primary" onClick={handleNextAnnotationClick}>
						<img className="image-inside-button" src={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? arrow_left : arrow_right} alt="Next" />
					</button>
				</div>

				{iframeUrl && iframeUrl.startsWith('http') ?
					<a href={iframeUrl} target="_blank" className="link-class"><FormattedMessage id="documentPreview.linkToDoc" /></a> : ''
				}

				<div className='searchBox-container'>
					<input
						id="searchBox"
						type="text"
						value={searchString}
						onChange={e => setSearchString(e.target.value)}
						placeholder={intl.formatMessage({ id: 'documentPreview.searchPlaceholder' })}
						className="form-control"
					/>

					<button id="findBtn" className="btn btn-primary" onClick={handleFindClick}					>
						<FormattedMessage id="documentPreview.searchButton" />
					</button>
				</div>
			</div>

			<div className="pdf-preview-container">
				{shouldShowPDFViewer && <PDFViewer {...{ file: iframeUrl, searchText: activeSearchText, userQuery, piecesWithGeometry, shouldMarkText, nextAnnotation, prevAnnotation, parentID: ParentID }} />}
				{/* <iframe src={iframeUrl ? `https://docs.google.com/gview?embedded=true&url=${iframeUrl}` : ''} width="100%" height="100%" frameBorder="0" title="PDF Preview"></iframe> */}
			</div>
		</div>
	);
};

export default DocumentPreview;

//Preview_LawsMetadata.tsx
import { FormattedMessage } from 'react-intl';

interface Preview_LawsMetadataProps {
    iframeUrl: string;
    piecesWithGeometry: any;
    userQuery: string;
    searchType: string;
    metaData: Record<string, any>;
}


const Preview_LawsMetadata: React.FC<Preview_LawsMetadataProps> = ({
    iframeUrl,
    userQuery,
    searchType,
    piecesWithGeometry,
    metaData
}) => {
    return (
        <div>
            <textarea readOnly value={metaData.LName || ''} className="form-control mb-3" rows={2} />
            <label className="mb-3">{metaData.LValidity || ''}</label>

            <div className="details-container row">
                {/* Note: the following section was commented out in your original code, so I've left it commented here as well.
                <div className="detail-item col-lg-4 col-md-6">
                    <label htmlFor="txtLValidity">
                        <FormattedMessage id="lawsMetadata.validity" />
                    </label>
                    <input type="text" readOnly value={metaData.LValidity || ''} className="form-control" />
                </div>
                */}
                <div className="detail-item col-lg-6 col-md-6">
                    <label htmlFor="txtLFirstPublication">
                        <FormattedMessage id="lawsMetadata.firstPublication" />
                    </label>
                    <input type="text" readOnly value={metaData.LFirstPublication || ''} id="txtLFirstPublication" className="form-control" />
                </div>
                <div className="detail-item col-lg-6 col-md-6">
                    <label htmlFor="txtLLastModified">
                        <FormattedMessage id="lawsMetadata.lastModified" />
                    </label>
                    <input type="text" readOnly value={metaData.LLastModified || ''} id="txtLLastModified" className="form-control" />
                </div>
                <div className="detail-item col-lg-6 col-md-6">
                    <label htmlFor="txtLMinister">
                        <FormattedMessage id="lawsMetadata.responsibleMinister" />
                    </label>
                    <input type="text" readOnly value={metaData.LMinister || ''} id="txtLMinister" className="form-control" />
                </div>
                <div className="detail-item col-lg-6 col-md-6">
                    <label htmlFor="txtLSubject">
                        <FormattedMessage id="lawsMetadata.subject" />
                    </label>
                    <input type="text" readOnly value={metaData.LSubject || ''} id="txtLSubject" className="form-control" />
                </div>
            </div>
        </div>
    );
}

export default Preview_LawsMetadata;
//Preview_DocumentsMetadata.tsx
import { FormattedMessage } from 'react-intl';
import { formatBytes } from '../../Services/utils';

interface Preview_DocumentsMetadataProps {
    iframeUrl: string;
    piecesWithGeometry: any;
    userQuery: string;
    searchType: string;
    metaData: Record<string, any>;
}


const Preview_DocumentsMetadata: React.FC<Preview_DocumentsMetadataProps> = ({
    iframeUrl,
    userQuery,
    searchType,
    piecesWithGeometry,
    metaData
}) => {
    return (
        <div>
            <textarea readOnly value={metaData.Name || ''} className="form-control mb-3" rows={2} />

            <div className="details-container row">
                <div className="detail-item col-lg-4 col-md-4">
                    <label htmlFor="txtLFirstPublication">
                        <FormattedMessage id="docsMetadata.Created" />
                    </label>
                    <input type="text" readOnly value={metaData.Created || ''} id="txtCreated" className="form-control" />
                </div>
                <div className="detail-item col-lg-4 col-md-4">
                    <label htmlFor="txtLLastModified">
                        <FormattedMessage id="docsMetadata.Modified" />
                    </label>
                    <input type="text" readOnly value={metaData.Modified || ''} id="txtModified" className="form-control" />
                </div>
                <div className="detail-item col-lg-4 col-md-4">
                    <label htmlFor="txtLMinister">
                        <FormattedMessage id="docsMetadata.Size" />
                    </label>
                    <input type="text" readOnly value={formatBytes(metaData.Size) || ''} id="txtSize" className="form-control" />
                </div>
            </div>
        </div>
    );
}

export default Preview_DocumentsMetadata;
// DocGenContentValidation.tsx
import React, { useState } from 'react';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { TextInput } from './DocGenContentInput';
import { svcCheckContentPiecesCoverage } from '../../Services/servicesWrapper';
import waitIcon from '../../images/loading_spinner.gif';
import { FormattedMessage } from 'react-intl';
import { extractProjectInfo } from './DocGenCommon';

type DocGenContentValidationProps = {
    includeProjectSummary: boolean;
    includeMarkedJudgements: boolean;
    textInputs: TextInput[];
    guidelines: string;
    contentGaps: ContentGapInfo[];
    setContentGaps: React.Dispatch<React.SetStateAction<ContentGapInfo[] | null>>;
};

export type ContentGapInfo = {
    missing_info: string;
    importance: number; // 1 - critical, 2 - important, 3 - optional
};

const DocGenContentValidation: React.FC<DocGenContentValidationProps> = ({ textInputs, guidelines, contentGaps, setContentGaps }) => {
    const { authState } = useSafeAuth();
    const [loading, setLoading] = useState(false);
    //const [gaps, setGaps] = useState<GapInfo[]>([]);

    const handleValidation = async () => {
        setLoading(true);

        const projectInfo = await extractProjectInfo(authState);
        const contentPieces = [...[JSON.stringify(projectInfo)], ...textInputs.map(input => input.content)];

        try {
            const response = await svcCheckContentPiecesCoverage(authState, contentPieces, guidelines, authState.currentLocale === 'he' ? "Hebrew" : "English");
            setContentGaps(response);
        } catch (error) {
            console.error('Error validating content:', error);
        }
        setLoading(false);
    };




    const getImportanceIcon = (importance: number) => {
        switch (importance) {
            case 1: return '❗'; // Critical
            case 2: return '⚠️'; // Important
            case 3: return 'ℹ️'; // Optional
            default: return '';
        }
    };

    return (
        <div>
            <button onClick={handleValidation} className="btn btn-primary" disabled={loading}>
                <FormattedMessage id="DocGenContentValidation.contentValidationBtn" />
            </button>
            {loading && <div className="text-center">
                <img src={waitIcon} alt="Loading..." className="my-2" />
                <p><FormattedMessage id="DocGenContentValidation.loading" /></p>
            </div>}
            {!loading && contentGaps.length > 0 && (
                <div className="mt-3">
                    <p><FormattedMessage id="DocGenContentValidation.contentGapsAndIssuesFound" /></p>
                    <ul className="list-group">
                        {contentGaps
                            .sort((a, b) => a.importance - b.importance)
                            .map((gap, index) => (
                                <li key={index} className="list-group-item">
                                    <span className="importance-icon">{getImportanceIcon(gap.importance)}</span>
                                    <span className="missing-info">{gap.missing_info}</span>
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </div>

    );
};

export default DocGenContentValidation;


// PDFViewer.tsx
// import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
// import extractPDFTextPieces from './extractPDFText';
import { PieceWithGeometry } from '../DocumentSearch/DocumentTypes';
//import { v4 as uuidv4 } from 'uuid';
// import { useAuth } from '../../firebase/AuthProvider';
// import pako from 'pako';
import loading_spinner from '../../images/loading_spinner.gif'
import './PDFViewer.css'
import firebaseApp from '../../firebase/firebaseApp';

interface PDFViewerProps {
	file: string;
	searchText: string;
	shouldMarkText: boolean;
	onMarkingCompleted?: () => void;
	prevAnnotation: number;
	nextAnnotation: number;
	userQuery: string;
	piecesWithGeometry: any[];
	parentID: string;
}

const customAnnotationFlags = {
	showToolbar: false,   /* Default value is true */
	showCommentsPanel: false,  /* Default value is true */
	downloadWithAnnotations: true,  /* Default value is false */
	printWithAnnotations: true,  /* Default value is false */
};

declare let AdobeDC: any;
const isProduction = process.env.NODE_ENV === 'production';
const adobeClientId = isProduction ? (firebaseApp.appName == 'legal' ? "2dda6d298b55400eb4deddb0301cbaa6" : '44418387ead7499082db9b7c9018aa3b') :
	"c5060475a8664858b9a78fd22c2cb0c4";
console.log(isProduction ? 'production' : 'development');

const PDFViewer: React.FC<PDFViewerProps> = ({ file, piecesWithGeometry, searchText, userQuery, shouldMarkText, onMarkingCompleted, prevAnnotation, nextAnnotation, parentID }) => {
	const apisRef = useRef<any>(null);
	const adobeViewerRef = useRef<any>(null);
	// const authState = useAuth();
	const [docLoadingState, setDocLoadingState] = useState<string>("");
	const [currAnnotationID, setCurrAnnotationID] = useState<string>("");
	const textDataRef = useRef<any>(null);



	useEffect(() => {
		selectPrevAnnotation();
	}, [prevAnnotation]);
	useEffect(() => {
		selectNextAnnotation();
	}, [nextAnnotation]);

	// Initialization and File Loading
	useEffect(() => {
		if (file) {
			if (piecesWithGeometry) {
				setDocLoadingState("file_and_piecesWithGeometry_available");
				initializeAdobeViewer();
			} else {
				setDocLoadingState("piecesWithGeometry_loading");
			}
		} else {
			setDocLoadingState("file_not_available");
		}
	}, [file, piecesWithGeometry]);


	const initializeAdobeViewer = async () => {
		if (!file) return;

		const adobeDCView = new AdobeDC.View({
			clientId: adobeClientId,
			divId: parentID + ".pdf-viewer",
			sendAutoPDFAnalytics: false
		});

		try {
			const adobeViewer: any = await adobeDCView.previewFile({
				content: { location: { url: file } },
				metaData: { fileName: 'PDF', id: file },
			}, {
				enableSearchAPIs: true,
				defaultViewMode: "FIT_WIDTH", //"FULL_PAGE",
				//embedMode: "SIZED_CONTAINER",
				//showAnnotationTools: true,
				showDownloadPDF: false,
				includePDFAnnotations: false,
				enableAnnotationAPIs: true,
				showPrintPDF: true,
				enableFormFilling: true
			});

			console.log('Adobe Viewer initialized', adobeViewer);

			const apis: any = await adobeViewer.getAPIs();

			//console.log('APIs received', apis);
			apisRef.current = apis; // Store the APIs for later use

			adobeViewerRef.current = adobeViewer;

			if (piecesWithGeometry) markTextByRelevance();

		} catch (error) {
			console.error('Error during Adobe viewer initialization', error);
		}
	};



	// Search functionality
	useEffect(() => {
		searchInPdf();
	}, [searchText]); // Only re-run the effect if `searchText` changes

	useEffect(() => {
		if (shouldMarkText) {
			markTextByRelevance();
		}
	}, [shouldMarkText]);

	const searchInPdf = () => {
		if (apisRef.current && searchText) {
			apisRef.current.search(searchText).then((searchObject: any) => {
				searchObject.onResultsUpdate((res: any) => {
					// Callback function logic
					console.log(res);
				}).then((result: any) => {
					console.log(result);
				}).catch((error: any) => {
					console.log(error);
				});
			}).catch((error: any) => {
				console.log(error);
			});
		}
	}

	//let currAnnotationID = '';
	const selectPrevAnnotation = async () => {
		let textData = textDataRef.current;
		if (prevAnnotation == 0 || !textData) return;
		const currentIndex = textData.findIndex((item: PieceWithGeometry) => item.annotationId === currAnnotationID);
		if (currentIndex === -1) return;

		let newIndex = currentIndex - 1;
		if (newIndex >= 0) {
			let newAnnotationId = textData[newIndex].annotationId;
			if (adobeViewerRef.current) {
				let annotationManager = await adobeViewerRef.current.getAnnotationManager();
				if (annotationManager && newAnnotationId) {
					await annotationManager.selectAnnotation(newAnnotationId);
					setCurrAnnotationID(newAnnotationId);
				}
			}
		}
	}
	const selectNextAnnotation = async () => {
		let textData = textDataRef.current;
		if (nextAnnotation == 0 || !textData) return;
		const currentIndex = textData.findIndex((item: PieceWithGeometry) => item.annotationId === currAnnotationID);
		if (currentIndex === -1) return;

		let newIndex = currentIndex + 1;
		if (newIndex < textData.length) {
			let newAnnotationId = textData[newIndex].annotationId;
			if (adobeViewerRef.current) {
				let annotationManager = await adobeViewerRef.current.getAnnotationManager();
				if (annotationManager && newAnnotationId) {
					await annotationManager.selectAnnotation(newAnnotationId);
					setCurrAnnotationID(newAnnotationId);
				}
			}
		}
	}


	const markTextByRelevance = async () => {
		const addAnnotationsByTextsRelevance = async () => {
			try {
				if (piecesWithGeometry?.length) {
					let textData: PieceWithGeometry[] | undefined = [];
					textData = piecesWithGeometry.map((p) => { return { pageIndex: p.pageIndex, relevance: p.similarity, boundingBox: p.boundingBox, text: '', annotationId: '', opacity: 0 }; })

					if (textData) {

						const adobeViewer = adobeViewerRef.current;
						// eslint-disable-next-line prefer-const
						let annotationManager = await adobeViewer.getAnnotationManager();
						let maxRelevance = 0;
						let mostRelevantAnnotation = null;

						await annotationManager.setConfig(customAnnotationFlags);

						//for(let index = textData.length - 1; index >= 0 ; index --) { //textData.length
						for (let index = 0; index < textData.length; index++) { //textData.length
							let p = textData[index];

							let visual = mapRelevanceToVisual(textData[index].relevance);
							if (visual.opacity > 0) {

								const annotationId = `annotation_${index}`;
								if (textData[index].relevance > maxRelevance) { maxRelevance = textData[index].relevance; mostRelevantAnnotation = annotationId; }
								textData[index].annotationId = annotationId;
								//currAnnotationID = textData[index].annotationId;

								// if (!(pagesSizes as any)[`p.${p.pageIndex}`]) {
								// 	(pagesSizes as any)[`p.${p.pageIndex}`] = await getPageSize(p.pageIndex);
								// }
								// let pageWidth = (pagesSizes as any)[`p.${p.pageIndex}`]?.width || 600;

								p.boundingBox[0] = 0;
								p.boundingBox[1] -= 7;
								const [_1, Ymin, _2, Ymax] = p.boundingBox;

								let annotationAdded = false; let pageWidth = 1000;
								while (!annotationAdded && pageWidth >= 400) {
									try {
										p.boundingBox[2] = pageWidth;
										const quadPoints = [p.boundingBox[0], Ymax, p.boundingBox[2], Ymax,
										p.boundingBox[0], Ymin, p.boundingBox[2], Ymin];

										const annotation = createAnnotation(annotationId, file, p, visual, quadPoints);
										await annotationManager.addAnnotations([annotation]);
										annotationAdded = true;
									} catch (e_annotation_add) {
										pageWidth -= 400;
									}
								}
							}
						}

						await annotationManager.setConfig(customAnnotationFlags);
						if (mostRelevantAnnotation) {
							await annotationManager.selectAnnotation(mostRelevantAnnotation);
							setCurrAnnotationID(mostRelevantAnnotation);
						}

						textData.sort((a, b) => { return (a.relevance > b.relevance) ? -1 : ((a.relevance < b.relevance) ? 1 : 0); });
						textDataRef.current = textData;

					}

				}
			} catch (error) {
				console.log('Error in addAnnotationsByTextsRelevance', error);
			}
			//let test = 1;
		}

		await addAnnotationsByTextsRelevance();
		if (typeof onMarkingCompleted === "function") {
			onMarkingCompleted();
		}
	}

	function mapRelevanceToVisual(relevance: number): { color: string, opacity: number } {
		//return relevanceArray.map((relevance) => {
		// const HIGH_RELEVANCE = 0.9;
		// const LOW_RELEVANCE = 0.82;
		//const HIGH_RELEVANCE = 0.7; // 0.84;
		//const LOW_RELEVANCE = 0.56; // 0.7;
		const HIGH_RELEVANCE = 0.7; // 0.84;
		const LOW_RELEVANCE = 0.36; // 0.7;

		let opacity = 0;
		//let redComponent = 0xFF;  // Starts at 0xFF and decreases to 0x00

		if (relevance < LOW_RELEVANCE) {
			opacity = 0;
		} else if (relevance > HIGH_RELEVANCE) {
			opacity = 0.5;
			//redComponent = 0x00;  // No red for high relevance
		} else {
			const ratio = (relevance - LOW_RELEVANCE) / (HIGH_RELEVANCE - LOW_RELEVANCE);
			// Use a power function to make the opacity curve concave upward
			opacity = Math.pow(ratio, 2) * 0.5;
			//redComponent = Math.round((1 - ratio) * 0xFF);  // Interpolate between 0xFF and 0x00
		}

		const color = `#FFFF00`; //`#${redComponent.toString(16).padStart(2, '0')}FF00`;

		return {
			color: color,
			opacity: opacity
		};
	}

	return (
		<div className='pdf-viewer-container'>
			<div
				id={parentID + ".pdf-viewer"}
				key={parentID + ".pdf-viewer"}
				style={{
					height: parentID == "MarkedJudgements" ? "44vh" : "51vh",
					visibility: docLoadingState === "file_and_piecesWithGeometry_available" ? "visible" : "hidden"
				}}
			>
				{/* Adobe PDF Embed API will insert the PDF here */}
			</div>

			{docLoadingState === "piecesWithGeometry_loading" && (
				<img id="pdf-loading-image" className="loading" src={loading_spinner} alt="Loading..." />
			)}
		</div>
	);



};

export default PDFViewer;

function createAnnotation(annotationId: string, file: string, p: PieceWithGeometry, visual: { color: string; opacity: number; }, quadPoints: number[]) {
	return {
		"@context": [
			"https://www.w3.org/ns/anno.jsonld",
			"https://comments.acrobat.com/ns/anno.jsonld"
		],
		"type": "Annotation",
		"id": annotationId,
		"bodyValue": "",
		"motivation": "commenting",
		"target": {
			"source": file,
			"selector": {
				"node": {
					"index": p.pageIndex, // Put the actual page index here
				},
				"opacity": visual.opacity,
				"type": "AdobeAnnoSelector",
				"subtype": "highlight",
				"boundingBox": p.boundingBox,
				"quadPoints": quadPoints,
				"strokeColor": visual.color,
				"strokeWidth": 0,
			}
		},
		"creator": {
			"type": "Person",
			"name": "Wisanna", // Change as needed
		},
		"created": new Date().toISOString(),
		"modified": new Date().toISOString(),
	};
}


// LegalModuleLaws.tsx
import { useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import DocumentSearch from '../DocumentSearch/DocumentSearch';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import styles from '../UIGeneral/styles.module.css';
import './../LegalModuleJudgements/LegalModuleJudgements.css';
import { IDoc } from '../DocumentSearch/DocumentTypes'
import { useIntl } from 'react-intl';




function LegalModuleLaws() {
  const intl = useIntl();

  const [selectedDoc, setSelectedDoc] = useState<IDoc | null>(null);
  const [userQuery, setUserQuery] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('');

  // Handle document selection
  const handleDocumentSelect = (doc: IDoc | null) => {
    setSelectedDoc(doc);
  };
  const onUserQueryChange = (adoptedQuery: string, searchType: string) => {
    setUserQuery(adoptedQuery);
    setSearchType(searchType);
  }

  const columns = [
    { id: 'index', title: "#", render: (index: any) => index },
    { id: 'relevance', title: "@", render: (doc: any) => doc?.score },
    { id: 'caseNumber', title: intl.formatMessage({ id: 'legalModuleLaws.firstPublication' }), render: (doc: any) => doc?.metaData['first_publication'] },
    { id: 'lastPublication', title: intl.formatMessage({ id: 'legalModuleLaws.lastPublication' }), render: (doc: any) => doc?.metaData['last_publication'] },
    { id: 'minister', title: intl.formatMessage({ id: 'legalModuleLaws.minister' }), render: (doc: any) => doc?.metaData['minister'] },
    { id: 'law', title: intl.formatMessage({ id: 'legalModuleLaws.law' }), render: (doc: any) => doc.overview }
  ];


  return (
    <div className={[styles.Container, "legal-module-search"].join(" ")}>
      <PanelGroup className="legal-module-panes" autoSaveId="moduleLawsPanesResizer" direction="horizontal">
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 2 : 1}>
          <div className={[styles.PanelContent, "legal-search"].join(" ")}>
            <DocumentSearch onDocumentSelect={handleDocumentSelect} onUserQueryChange={onUserQueryChange} ParentId="Laws" key="SearchLaws" resultsColumns={columns} />
          </div>
        </Panel>
        <ResizeHandle className='splitter' />
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 1 : 2}>
          <div className={[styles.PanelContent, "legal-preview"].join(" ")}>
            <DocumentPreview
              iframeUrl={selectedDoc?.url ?? ''}
              userQuery={userQuery}
              searchType={searchType}
              piecesWithGeometry={selectedDoc?.piecesWithGeometry}
              ParentID='Laws'
              ContentType='Laws'
              metaData={{
                LName: selectedDoc?.overview ?? '', LValidity: selectedDoc?.metaData['validity'] || '',
                LFirstPublication: selectedDoc?.metaData['first_publication'] || '',
                LLastModified: selectedDoc?.metaData['last_publication'] || '',
                LMinister: selectedDoc?.metaData['minister'] || '',
                LSubject: selectedDoc?.metaData['subject'] || ''
              }}
            />
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
}

export default LegalModuleLaws;

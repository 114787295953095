// DocGenAccordion.tsx
import React, { useState } from 'react';
import './ModuleDocumentGenerate.css';

interface AccordionItem {
    header: string | JSX.Element;
    content: JSX.Element;
}

interface DocGenAccordionProps {
    items: AccordionItem[];
}

const DocGenAccordion: React.FC<DocGenAccordionProps> = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);

    const toggleItem = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(null); // Collapse the currently active item
        } else {
            setActiveIndex(index); // Expand the clicked item
        }
    };

    return (
        <div className="accordion">
            {items.map((item, index) => (
                <div key={index} className="accordion-item">
                    <div className="accordion-title btn btn-link" onClick={() => toggleItem(index)}>
                        {(index + 1) + ". " + item.header}
                    </div>
                    <div className={`accordion-content ${index === activeIndex ? 'active' : 'hidden'}`}>
                        {item.content}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DocGenAccordion;


// ModuleDocumentsSearch.tsx
import { useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import DocumentSearch from '../DocumentSearch/DocumentSearch';
import styles from '../UIGeneral/styles.module.css';
import './ModuleDocumentsSearch.css';
import { IDoc } from '../DocumentSearch/DocumentTypes'
import { useIntl } from 'react-intl';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import { formatBytes } from '../../Services/utils';


function ModuleDocumentsSearch() {
  const intl = useIntl();
  const [selectedDoc, setSelectedDoc] = useState<IDoc | null>(null);
  const [userQuery, setUserQuery] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('');

  // Handle document selection
  const handleDocumentSelect = (doc: IDoc | null) => {
    setSelectedDoc(doc);
  };
  const onUserQueryChange = (adoptedQuery: string, searchType: string) => {
    setUserQuery(adoptedQuery);
    setSearchType(searchType);
  }


  const columns = [
    { id: 'index', title: "#", render: (index: any) => index },
    { id: 'relevance', title: "@", render: (doc: any) => doc?.score },
    { id: 'created', title: intl.formatMessage({ id: "ModuleDocumentsSearch.dateCreated" }), render: (doc: any) => doc?.metaData?.createdTime },
    { id: 'modified', title: intl.formatMessage({ id: "ModuleDocumentsSearch.dateModified" }), render: (doc: any) => `${doc?.metaData?.modifiedTime}` },
    { id: 'size', title: intl.formatMessage({ id: "ModuleDocumentsSearch.size" }), render: (doc: any) => `${formatBytes(doc?.metaData?.size)}` },
    { id: 'fileName', title: intl.formatMessage({ id: "ModuleDocumentsSearch.fileName" }), render: (doc: any) => doc?.name },
  ];

  return (
    <div className={[styles.Container, "legal-module-search"].join(" ")}>
      <PanelGroup className="legal-module-panes" autoSaveId="moduleDocumentSearchPanesResizer" direction="horizontal">
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 2 : 1}>
          <div className={[styles.PanelContent, "legal-search"].join(" ")}>
            <DocumentSearch onDocumentSelect={handleDocumentSelect} onUserQueryChange={onUserQueryChange} ParentId="Documents" key="SearchDocuments" resultsColumns={columns} />
          </div>
        </Panel>
        <ResizeHandle className='splitter' />
        <Panel className={styles.Panel} collapsible={false} defaultSize={50} minSize={25} order={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? 1 : 2}>
          <div className={[styles.PanelContent, "legal-preview"].join(" ")}>
            <DocumentPreview
              iframeUrl={selectedDoc?.url ?? ''}
              userQuery={userQuery}
              searchType={searchType}
              piecesWithGeometry={selectedDoc?.piecesWithGeometry}
              ParentID='Documents'
              ContentType='Documents'
              metaData={{
                Name: selectedDoc?.overview ?? '', Created: selectedDoc?.metaData?.createdTime ?? '',
                Modified: selectedDoc?.metaData?.modifiedTime ?? '', Size: selectedDoc?.metaData?.size ?? ''
              }}
            />
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
}

export default ModuleDocumentsSearch;

// chatUtils.ts

//import { svcChat } from '../../Services/servicesWrapper';
import { v4 as uuidv4 } from 'uuid';

export interface Chat {
    id: string;
    title: string;
    last_updated: number;
}

export interface ChatMessage {
    id: string;
    date: number;
    type: 'u' | 'a'; // 'u' for user, 'a' for assistant
    text: string;
    corr_id?: string;
    response_completed: boolean;
}


const urlRegex = /(https?:\/\/[^\s^\\]]+)|\[(.*?)\]\((https?:\/\/[^\s^\\]]+)\)/g;
const boldTextRegex = /\*\*(.*?)\*\*/g;

export const codeCopyFormat = (text: string): string => {
    try {
        let text1 = text.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        //let ccc = btoa(text1);
        return text1;
    } catch (error) {
        console.error(`Error in codeCopyFormat. text="${text}"`, error);
        return text;
    }
}

// Custom UTF-8 safe base64 encoding function
export const utfSafeBtoa = (str: string): string => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
    }));
}

export const textToHTML = (text: string, intl_lang: string, updateCorrId: ((corr_id: string) => void) | null): string => {
    try {
        text = text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        text = text.replace(/<ans>|<\/ans>/g, '').trim();
        text = text.replace(/^\s*\n+|\n+$/g, '').trim();
        text = text.replace(/\\"/g, '"');

        let convertedText = '';

        //const codeBlockRegex = /```(\w+)\s*([\s\S]*?)```/g;
        const codeBlockRegex = /```(\w*)\s*([\s\S]*?)```/g;

        let lastIndex = 0;
        text.replace(codeBlockRegex, (match, lang, code, offset) => {
            convertedText += convertTextSegment(text.slice(lastIndex, offset), updateCorrId);
            convertedText += `
            <div class="code-block">
                <div class="code-header">                    
                    ${lang ? `<span class="code-language">${lang}</span>` : ''}
                    <button class="copy-code-button" onclick="

                        navigator.clipboard.writeText(decodeURIComponent(atob('${utfSafeBtoa(codeCopyFormat(code))}').split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')))
                        

                    ">${intl_lang == 'he' ? 'העתק' : 'Copy'}</button>
                </div>
                <pre class="code-content">${code}</pre>
            </div>
        `;

            //                        navigator.clipboard.writeText(atob('${btoa(codeCopyFormat(code))}'))

            lastIndex = offset + match.length;
            return match;
        });

        convertedText += convertTextSegment(text.slice(lastIndex), updateCorrId);
        return convertedText;
    } catch (error) {
        console.error('Error in textToHTML:', error);
        return `<pre>${text}</pre>`;
    }
};

function handleThinkingMessages(textSegment: string, updateCorrId: ((corr_id: string) => void) | null): string {
    // Split the text into lines
    const lines = textSegment.split('\n');

    // Check the last line to determine how to handle 'wisanna_sys' lines
    const lastLine = lines[lines.length - 1];

    let filteredLines;
    const thinkingMessagePrefix = 'wisanna_sys:';
    const corridMessagePrefix = 'wisanna_sys:corr_id';
    if (lastLine.startsWith(corridMessagePrefix) && updateCorrId) {
        // Extract the correlation ID from the last line
        const arrCorrId = lastLine.split('=');
        if (arrCorrId.length > 1) {
            updateCorrId(arrCorrId[1]);
        }
    }
    if (lastLine.startsWith(thinkingMessagePrefix) && !lastLine.startsWith(corridMessagePrefix)) {
        // If the last line contains 'wisanna_sys', show only the last 'thinking message'
        filteredLines = [lastLine.slice(thinkingMessagePrefix.length)];
    } else {
        // Otherwise, hide all 'thinking messages'
        filteredLines = lines.filter(line => !line.startsWith(thinkingMessagePrefix));
    }
    // Rejoin the filtered lines into a single text segment
    textSegment = filteredLines.join('\n');
    return textSegment;
}

function convertTextSegment(textSegment: string, updateCorrId: ((corr_id: string) => void) | null): string {
    const markdownLinkRegex = /\[([^\]]+)]\((https?:\/\/[^\s\\)\\(^\\[\]]+)\)/g;
    const plainUrlRegex = /(https?:\/\/[^\s^\\)\\(^\\[\]]+)/g;
    const imageRegex = /\[img:(https?:\/\/[^\s\\)\\(^\\[\]]+)\]/g;
    const csvRegex = /\[\[CSV:([\s\S]*?)]]/g;
    const chartRegex = /\[\[chart:({.*?})\]\]/g;

    textSegment = handleThinkingMessages(textSegment, updateCorrId);

    // Handle CSV tables
    textSegment = textSegment.replace(csvRegex, (match, csvContent) => {
        return convertCsvToHtmlTable(csvContent);
    });

    // Handle CHARTS
    textSegment = textSegment.replace(chartRegex, (match, chartConfig) => {
        const chartId = `chart-${uuidv4()}`;
        return `<div class="chart-container" data-chart-id="${chartId}" data-chart='${chartConfig}'></div>`;
    });

    // Handle images
    textSegment = textSegment.replace(imageRegex, (match, url) => {
        return `<div style="text-align: center;"><img src="${url}" style="width: 55%; height: auto;" alt="Image"></div>`;
    });


    // Replace markdown links
    textSegment = textSegment.replace(markdownLinkRegex, (match, label, url) => {
        // Check if label is a valid URL to display the domain instead
        if (isValidURL(label)) {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${extractDomainFromURL(label)}</a>`;
        } else {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${label}</a>`;
        }
    });


    // Replace plain URLs, avoiding those that are part of an 'href' attribute or 'src' attribute
    let lastIndex = 0;
    let result = '';
    let match;

    while ((match = plainUrlRegex.exec(textSegment)) !== null) {
        const url = match[0];
        const precedingText = textSegment.slice(lastIndex, match.index);

        if (!/(href|src)\s*=\s*["'][^"']*$/.test(precedingText)) {
            result += precedingText + `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        } else {
            result += precedingText + url;
        }

        lastIndex = plainUrlRegex.lastIndex;
    }

    result += textSegment.slice(lastIndex);
    textSegment = result;


    // Handle bold text
    textSegment = textSegment.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Handle list items
    textSegment = handleListItems(textSegment);

    // Handle remaining newlines
    textSegment = textSegment.replace(/\n/g, '<br>');

    // let r = new RegExp(/(?<=^|<br>\s*)#{2,6}\s*(.*?)(?=\s*(?:<br>|$))/g);
    // let m = textSegment.match(r);
    // if (m && m?.length > 0) {
    //     let ss = 1;
    // }

    // Handle lines starting with '###' for <h4> tags
    textSegment = textSegment.replace(/(?<=^|<br>\s*)#{2,6}\s*(.*?)(?=\s*(?:<br>|$))/g, '<h4 style="margin-bottom:0;">$1</h4>');
    textSegment = textSegment.replace(/<\/h4><br><br>/g, '</h4><br>');

    textSegment = textSegment.replace(/#{2,5}/g, '');


    return textSegment;
}

function convertCsvToHtmlTable(csvContent: string): string {
    const rows = csvContent.trim().split('\n');
    let htmlTable = '<table style="border-collapse: collapse; width: 100%; margin-bottom: 1em;">';

    rows.forEach((row, rowIndex) => {
        const cells = row.split(',');
        htmlTable += '<tr>';
        cells.forEach((cell, cellIndex) => {
            const cellContent = cell.trim();
            if (rowIndex === 0) {
                htmlTable += `<th style="border: 1px solid #ddd; padding: 8px; background-color: #f2f2f2; font-weight: bold; text-align: left;">${cellContent}</th>`;
            } else {
                htmlTable += `<td style="border: 1px solid #ddd; padding: 8px;">${cellContent}</td>`;
            }
        });
        htmlTable += '</tr>';
    });

    htmlTable += '</table>';
    return htmlTable;
}

function handleListItems(text: string): string {
    const lines = text.split('\n');
    let inList = false;
    let result = '';

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.startsWith('* ') || line.startsWith('- ')) {
            if (!inList) {
                result += '<ul>';
                inList = true;
            }
            result += `<li>${line.substring(2)}</li>`;
        } else {
            if (inList) {
                result += '</ul>';
                inList = false;
            }
            result += lines[i] + '\n';
        }
    }

    if (inList) {
        result += '</ul>';
    }

    return result;
}

// Helper functions
function isValidURL(urlString: string): boolean {
    try {
        new URL(urlString);
        return true;
    } catch (err) {
        return false;
    }
}

function extractDomainFromURL(urlString: string): string {
    try {
        const url = new URL(urlString);
        return url.hostname;
    } catch (err) {
        return urlString; // If it's not a valid URL, return the original input
    }
}
